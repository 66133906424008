<template>
  <div class="pt-4 pb-4 pl-4 pr-4">
    <form @submit.prevent="savePaidDocument()">

      <div class="form-group">
        <label>{{ $t('invoices.date') }}</label>
        <base-date-picker v-model="form.payment_date" :calendar-button="true" :invalid="$v.form.payment_date.$error"
          calendar-button-icon="calendar" @change="$v.form.payment_date.$touch()" :typeable='true' />
        <div v-if="$v.form.payment_date.$error">
          {{ $t('validation.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t('invoices.document_type') }}</label>
        <!--<base-input
          v-model="form.document_type"
          type="text"
        /> -->
        <base-select v-model="form.document_type" :options="paidDocumentOptions" :allow-empty="false" :show-labels="false"
          :placeholder="$t('invoices.document_type')" track-by="name" label="description" />
        <div v-if="$v.form.document_type.$error">
          {{ $t('validation.required') }}
        </div>
      </div>

      <div class="form-group">
        <label>{{ $t('invoices.amount') }}</label>
        <div class="base-input">
          <money v-model="form.amount" v-bind="customerCurrency" class="input-field" @input="$v.form.amount.$touch()" />
          <div v-if="$v.form.amount.$error">
            {{ $t('validation.required') }}
          </div>
        </div>
      </div>

      <div class="text-right">
        <button type="submit" class="btn btn-primary">
          {{ $t('invoices.save') }}
        </button>
      </div>

    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import decimalValidation from 'vuelidate/lib/validators/decimal';
import moment from 'moment';
import { mapActions, mapGetters } from 'vuex';

export default {
  mixins: [validationMixin],

  data: () => ({
    form: {
      document_type: null,
      payment_date: '',
      amount: 0,
    }
  }),

  computed: {
    ...mapGetters('currency', [
      'defaultCurrency',
    ]),
    ...mapGetters('invoice', [
      'dataModalAddPaidDocument',
    ]),
    ...mapGetters('catalogs', [
      'paidDocumentOptions',
      'searchPaidDocumentOption',
    ]),

    customerCurrency() {
      if (this.defaultCurrency) {
        return {
          decimal: this.defaultCurrency.decimal_separator,
          thousands: this.defaultCurrency.thousand_separator,
          prefix: this.defaultCurrency.symbol + ' ',
          precision: this.defaultCurrency.precision,
          masked: false
        }
      } else {
        return this.defaultCurrenctForInput
      }
    },

  },

  validations: {
    form: {
      document_type: {
        required,
      },
      payment_date: {
        required,
      },
      amount: {
        required,
        decimal: decimalValidation,
      }
    }
  },

  created() {
    this.form.document_type = this.searchPaidDocumentOption('direct_collection')
  },
  mounted() {
    this.form.payment_date = moment().toISOString();
    this.form.amount = this.dataModalAddPaidDocument.amount;
  },

  methods: {
    ...mapActions('invoice', [
      'fetchSavePaidDocument',
    ]),
    ...mapActions('modal', [
      'closeModal',
    ]),

    savePaidDocument() {
      this.$v.form.$touch();
      if (this.$v.form.$invalid !== false) {
        return;
      }

      const data = {
        ...this.form,
        document_type: this.form.document_type.name,
        amount: Number(this.form.amount) * 100,
        invoice_id: this.dataModalAddPaidDocument.invoiceId,
      }

      this.fetchSavePaidDocument(data)
        .then(response => {
          if (response.data.success) {
            window.toastr['success'](this.$t('invoices.messages.paid_document_added'));
            window.hub.$emit('paid_document_added');
            this.closeModal();
          } else {
            window.toastr['error'](this.$t('invoices.errors.cant_apply_payment'));
          }
        })
        .catch(err => {
          window.toastr['error'](this.$t('invoices.errors.paid_document_add'));
        })
    },

  }

}
</script>

