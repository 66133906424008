import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index.js'

/*
 |--------------------------------------------------------------------------
 | Admin Views
 |--------------------------------------------------------------------------|
 */

// Layouts
import LayoutBasic from './views/layouts/LayoutBasic.vue'
import LayoutCustomerSelectOne from './views/layouts/LayoutCustomerSelectOne.vue'
import LayoutCustomerSelectTwo from './views/layouts/LayoutCustomerSelectTwo.vue'

import LayoutLogin from './views/layouts/LayoutLogin.vue'
import LayoutWizard from './views/layouts/LayoutWizard.vue'
import LayoutWizardUser from './views/layouts/LayoutWizardUser.vue'

import LayoutCustomer from './views/layouts/LayoutCustomer.vue'

// Auth
import Login from './views/auth/Login.vue'
import ForgotPassword from './views/auth/ForgotPassword.vue'
import ResetPassword from './views/auth/ResetPassword.vue'
import Register from './views/auth/Register.vue'

import NotFoundPage from './views/errors/404.vue'

/*
 |--------------------------------------------------------------------------
 | Admin Views
 |--------------------------------------------------------------------------|
 */

// Dashbord
import Dashboard from './views/dashboard/Dashboard.vue'

// Customers
import CustomerIndex from './views/customers/Index.vue'
import CustomerCreate from './views/customers/Create.vue'
import CustomerPreviousInvoices from './views/customers/PreviousInvoices'
import CustomerSatSettings from './views/customers/SatSettings'
import CustomerContratacion from './views/customers/Contratacion'

// Items
import ItemsIndex from './views/items/Index.vue'
import ItemCreate from './views/items/Create.vue'

// Invoices
import InvoiceIndex from './views/invoices/Index.vue'
import InvoiceCreate from './views/invoices/Create.vue'
import InvoiceView from './views/invoices/InvoiceDetails.vue'
import InvoiceFromSat from './views/invoices/FromSat.vue'

//Pending for mont

import PendingIndex from './views/pendientes/index.vue'
import ContratadasIndex from './views/contratadas/index.vue'

//users
import UsersIndex from './views/users/index.vue'
import UsersCreate from './views/users/Create.vue'

// Payments
import PaymentsIndex from './views/payments/Index.vue'
import PaymentCreate from './views/payments/CreatePayment.vue'
import PaymentView from './views/payments/View.vue'
import PaymentGeneralPublicInvoice from './views/payments/CreateGeneralPublicInvoice.vue'
import PaymentCreateInvoice from './views/payments/CreateInvoice.vue'

// Nomina
import NominaIndex from './views/nomina/Index.vue'
// import PaymentCreate from './views/payments/CreatePayment.vue'
// import PaymentView from './views/payments/View.vue'
// import PaymentGeneralPublicInvoice from './views/payments/CreateGeneralPublicInvoice.vue'
// import PaymentCreateInvoice from './views/payments/CreateInvoice.vue'

// Estimates
import EstimateIndex from './views/estimates/Index.vue'
import EstimateCreate from './views/estimates/Create.vue'
import EstimateView from './views/estimates/View.vue'

// Expenses
import ExpensesIndex from './views/expenses/Index'
import ExpenseCreate from './views/expenses/CreateExpense.vue'
import ExpensesAll from './views/expenses/AllExpenses.vue'
import ExpensesQuickAssignment from './views/expenses/QuickAssignment.vue'

// Report
import SalesReports from './views/reports/SalesReports'
import ExpensesReport from './views/reports/ExpensesReport'
import ProfitLossReport from './views/reports/ProfitLossReport'
import TaxReport from './views/reports/TaxReport.vue'
import ReportLayout from './views/reports/layout/Index.vue'

// Settings
import SettingsLayout from './views/settings/layout/Index.vue'
import CompanyInfo from './views/settings/CompanyInfo.vue'
import Customization from './views/settings/Customization.vue'
import Notifications from './views/settings/Notifications.vue'
import Preferences from './views/settings/Preferences.vue'
import UserProfile from './views/settings/UserProfile.vue'
import TaxTypes from './views/settings/TaxTypes.vue'
import ExpenseCategory from './views/settings/ExpenseCategory.vue'
import MailConfig from './views/settings/MailConfig.vue'
import UpdateApp from './views/settings/UpdateApp.vue'

import Wizard from './views/wizard/Index.vue'

// Treasury
import TreasuryAccountsIndex from './views/treasury_accounts/Index.vue'
import TreasuryAccountCreate from './views/treasury_accounts/Create.vue'
import TreasuryAccountRelateInvoices from './views/treasury_accounts/RelateInvoices.vue'
import TreasuryAccountConciliations from './views/treasury_accounts/Conciliations'
import TreasuryAccountUploadTransactions from './views/treasury_accounts/UploadTransactions'
import TreasuryGeneralPublicInvoice from './views/treasury_accounts/CreateGeneralPublicInvoice.vue'

//accouts 
import AccountsIndex from './views/accounts/Index.vue'
import AccountsCreate from './views/accounts/Create.vue'



// Retentions
import RetencionesIndex from './views/retenciones/Index.vue'
import RetencionesView from './views/retenciones/View.vue'

// Concept Categories
import ConceptCategoryIndex from './views/concept_components/Index.vue'
import ConceptCategoryPersonal from './views/concept_components/Personal.vue'

// Pagos
import PagosIndex from './views/pagos/index.vue'

//Chat
import CustomerChat from './views/chat/index.vue'

// Paquetes
import PaquetesIndex from './views/paquetes/index.vue'

import CustomerProdServGroups from './views/customer_prod_serv/Index.vue'

// RATE TABLES
import RateTablesIndex from './views/rate_tables/Index'
import RateTablesTableRegime from './views/rate_tables/views/TableRegime'
import RateTablesGenerateYearTables from './views/rate_tables/GenerateYearTables'

// Tax Return
import TaxReturnIndex from './views/tax_return/Index'
import CreateTaxReturn from './views/tax_return/Create'
import TaxLosses from './views/tax_return/TaxLosses'
import TaxLossDetails from './views/tax_return/TaxLossDetails'
import UploadCFDI from './views/manual/UploadCFDI'

// Tax ReturnGlobal
import TaxReturnGlobalIndex from './views/tax_return_global/Index'

// Tax StatemetnAccount
import StatementAccountGlobalIndex from './views/statement_account_global/Index'
import StatementAccountGlobalList from './views/statement_account_global/List_files'

/*
 *|--------------------------------------------------------------------------
 *| Customer Views
 *|--------------------------------------------------------------------------|
 */
import CustomerDashboardIndex from './views/customer_dashboard/dashboard/Index'
import CustomerCertificatesIndex from './views/customer_dashboard/certificates/Index'
import CustomerTaxReturnIndex from './views/customer_dashboard/tax_return/Index'
import CustomerTaxReturnDetails from './views/customer_dashboard/tax_return/Details'
import currentCustomer from './store/modules/current-customer/index.js'
import { mapGetters } from 'vuex'

//  Wizard user

import RegistroUser from './views/wizardUser/RegistroUser'
import Contratacion from './views/wizardUser/Contratacion'
import Payment from './views/wizardUser/Payment'
import Contrato from './views/wizardUser/Contrato'

Vue.use(VueRouter)
const map = {
  ...mapGetters('CurrentCustomer', ['currentCustomer']),
}
const routes = [
  /*
     |--------------------------------------------------------------------------
     | Frontend Routes
     |--------------------------------------------------------------------------|
     */

  /*
     |--------------------------------------------------------------------------
     | Auth & Registration Routes
     |--------------------------------------------------------------------------|
     */

  {
    path: '/',
    component: LayoutLogin,
    meta: { redirectIfAuthenticated: true },
    children: [
      {
        path: '/',
        component: Login,
      },
      {
        path: 'login',
        component: Login,
        name: 'login',
      },
      {
        path: '/forgot-password',
        component: ForgotPassword,
        name: 'forgot-password',
      },
      {
        path: '/reset-password/:token',
        component: ResetPassword,
        name: 'reset-password',
      },
      {
        path: 'register',
        component: Register,
        name: 'register',
      },
    ],
  },
  {
    path: '/wizard',
    component: LayoutWizardUser,
    meta: { redirectIfAuthenticated: false },
    children: [
      {
        path: '/',
        component: RegistroUser, // Usar LayoutBasic para la ruta '/'
      },
      {
        path: 'contratacion',
        component: Contratacion, // Usar LayoutBasic para la ruta '/'
      },
      {
        path: 'payment',
        component: Payment, // Usar LayoutBasic para la ruta '/'
      },
      {
        path: 'contrato',
        component: Contrato, // Usar LayoutBasic para la ruta '/'
      },
    ],
  },

  /*
     |--------------------------------------------------------------------------
     | Onboarding Routes
     |--------------------------------------------------------------------------|
     */
  {
    path: '/on-boarding',
    component: LayoutWizard,
    children: [
      {
        path: '/',
        component: Wizard,
        name: 'wizard',
      },
    ],
  },
  /*
     |--------------------------------------------------------------------------
     | Admin Backend Routes
     |--------------------------------------------------------------------------|
     */
  {
    path: '/general',
    component: {
      render: (c) => c('router-view'),
    },
    meta: { requiresAuth: true, breadCrumb: ' ' },
    children: [
      // Dashbord
      {
        path: '/',
        redirect: { name: 'pending' },
        //name: 'dashboard'
      },
      {
        path: 'dashboard',
        redirect: { name: 'pending' },

        //Dashboard
      },

      // Customer
      {
        path: 'customers',
        component: LayoutBasic,
        meta: { breadCrumb: 'Clientes' },
        children: [
          {
            path: '/',
            component: CustomerIndex, //Dashboard,
            //name: 'dashboard'
          },
          {
            path: 'pending',
            name: 'pending',
            component: PendingIndex,
            meta: {
              breadCrumb: 'Pendientes del mes',
            },
          },
          {
            path: 'create',
            name: 'customers.create',
            component: CustomerCreate,
            meta: {
              breadCrumb: 'Crear Cliente',
            },
          },

          {
            path: '/',
            component: {
              render: (c) => c('router-view'),
            },
            meta: {
              breadCrumb: map.currentCustomer.id,
            },
            children: [
              {
                path: ':id/contratacion',
                name: 'Contrataciones',
                component: CustomerContratacion,
                meta: {
                  breadCrumb: 'Declaraciones',
                },
              },
              {
                path: ':id/edit',
                name: 'customers.edit',
                component: CustomerCreate,
                meta: {
                  breadCrumb: 'Editar Cliente',
                },
              },
              {
                path: ':id/previous-invoices',
                name: 'customer.previous-invoices',
                component: CustomerPreviousInvoices,
                meta: {
                  breadCrumb: 'Facturas anteriores',
                },
              },
              {
                path: ':id/sat-settings',
                name: 'customer.sat-settings',
                component: CustomerSatSettings,
                meta: {
                  breadCrumb: 'Ajustes SAT',
                },
              },
            ],
          },
        ],
      },
      //customer
      {
        path: 'customer',
        name: 'customer.index',
        component: LayoutCustomerSelectOne, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Cliente',
        },
        children: [
          {
            path: '/',
            redirect: { name: 'contracted.index' },
          },
          {
            path: 'contracted',
            name: 'contracted.index',
            component: ContratadasIndex,
            meta: {
              breadCrumb: 'Contratadas',
            },
          },
          {
            path: 'tax-return',
            name: 'tax-return-global.index',
            component: TaxReturnGlobalIndex,
            meta: {
              breadCrumb: 'Declaraciones',
            },
          },
          {
            path: 'statement-account',
            name: 'statement-account-global.index',
            component: StatementAccountGlobalIndex,
            meta: {
              breadCrumb: 'Estados de cuenta',
            },
          },
          {
            path: 'statement-account-list/:id',
            name: 'statement-account-global.list',
            component: StatementAccountGlobalList,
            meta: {
              breadCrumb: 'Estados de cuenta',
            },
          },
        ],
      },

      //Users
      {
        path: 'users',
        name: 'users',
        component: LayoutBasic, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Usuarios',
        },
        children: [
          {
            path: '/',
            component: UsersIndex,
            meta: {
              breadCrumb: 'Pendientes del mes',
            },

            //name: 'dashboard'
          },
          {
            path: 'create',
            component: UsersCreate,
            meta: {
              breadCrumb: 'crear',
            },

            //name: 'dashboard'
          },
          {
            path: ':id/edit',
            name: 'editarUsuario',
            component: UsersCreate,
            meta: {
              breadCrumb: 'Editar Usuario',
            },
          },
        ],
      },
      //chats
      {
        path: 'chat',
        name: 'chat.index',
        component: LayoutCustomerSelectTwo, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Mensajes',
        },
        children: [
          {
            path: '/',
            component: CustomerChat,
          },
        ],
      },
      // Pagos
      {
        path: 'pagos',
        name: 'pagos.index',
        component: LayoutCustomerSelectTwo, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Pagos',
        },
        children: [
          {
            path: '/',
            component: PagosIndex,
          },
        ],
      },
      // Paquetes
      {
        path: 'paquetes',
        name: 'paquetes.index',
        component: LayoutBasic, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Paquetes',
        },
        children: [
          {
            path: '/',
            component: PaquetesIndex,
          },
        ],
      },
      // Items
      {
        path: 'items',
        component: LayoutBasic, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Articulos',
        },
        children: [
          {
            path: '/',
            component: ItemsIndex,
          },
          {
            path: 'create',
            name: 'items.create',
            component: ItemCreate,
          },
          {
            path: ':id/edit',
            name: 'items.edit',
            component: ItemCreate,
          },
        ],
      },
      // Estimate
      {
        path: 'estimates',
        component: LayoutBasic, //TODO: CAMBIAR LAYOUT
        children: [
          {
            path: '/',
            component: EstimateIndex,
          },
          {
            path: 'create',
            name: 'estimates.create',
            component: EstimateCreate,
          },
          {
            path: ':id/view',
            name: 'estimates.view',
            component: EstimateView,
          },
          {
            path: ':id/edit',
            name: 'estimates.edit',
            component: EstimateCreate,
          },
        ],
      },
      // Invoice
      {
        path: 'invoices',
        name: 'invoices.index',
        component: LayoutCustomerSelectTwo, //TODO: CAMBIAR LAYOUT
        meta: {
          breadCrumb: 'Facturas',
        },
        children: [
          {
            path: '/',
            component: InvoiceIndex,
          },
          {
            path: 'from-sat',
            name: 'invoices.from-sat',
            component: InvoiceFromSat,
          },
          {
            path: 'create',
            name: 'invoices.create',
            component: InvoiceCreate,
          },
          {
            path: ':id/view',
            name: 'invoices.view',
            component: InvoiceView,
            meta: {
              breadCrumb: 'View',
            },
          },
          {
            path: ':id/edit',
            name: 'invoices.edit',
            component: InvoiceCreate,
          },
        ],
      },

      // {
      //   path: 'contracted',
      //   // name: 'contracted.index',
      //   component: ContratadasIndex,
      //   meta: {
      //     breadCrumb: 'Contratadas',
      //   },
      // },

      // Payments
      {
        path: 'payments',
        name: 'payments.index',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Ingresos',
        },
        children: [
          {
            path: '/',
            component: PaymentsIndex, //Dashboard,
            //name: 'dashboard'
          },
          {
            path: 'create',
            name: 'payments.create',
            component: PaymentCreate,
            meta: {
              breadCrumb: 'Agregar ingreso',
            },
          },
          {
            path: ':id/create',
            name: 'invoice.payments.create',
            component: PaymentCreate,
          },
          {
            path: ':id/edit',
            name: 'payments.edit',
            component: PaymentCreate,
          },
          {
            path: ':id/view',
            name: 'payments.view',
            component: PaymentView,
          },
        ],
      },
      {
        path: 'nomina',
        name: 'nomina.index',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Nomina',
        },
        children: [
          {
            path: '/',
            component: NominaIndex, //Dashboard,
            //name: 'dashboard'
          },
          // {
          //   path: 'create',
          //   name: 'payments.create',
          //   component: PaymentCreate,
          //   meta: {
          //     breadCrumb: 'Agregar ingreso',
          //   },
          // },
          // {
          //   path: ':id/create',
          //   name: 'invoice.payments.create',
          //   component: PaymentCreate,
          // },
          // {
          //   path: ':id/edit',
          //   name: 'payments.edit',
          //   component: PaymentCreate,
          // },
          // {
          //   path: ':id/view',
          //   name: 'payments.view',
          //   component: PaymentView,
          // },
        ],
      },
      {
        path: '/',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Ingresos',
        },
        children: [
          {
            path: 'create-invoice/:type',
            name: 'create-invocie.index',
            component: PaymentCreateInvoice,
            meta: {
              breadCrumb: 'Factura',
            },
          },
        ],
      },
      {
        path: '/',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Ingresos',
        },
        children: [
          {
            path: 'general-public-invoice/:type',
            name: 'general-public-invocie.index',
            component: PaymentGeneralPublicInvoice,
            meta: {
              breadCrumb: 'Público General',
            },
          },
        ],
      },

      // Expenses
      {
        path: 'expenses',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Egresos',
        },
        children: [
          {
            path: '/',
            component: ExpensesIndex, //Dashboard,
            //name: 'dashboard'
          },
          {
            path: 'create',
            name: 'expenses.create',
            component: ExpenseCreate,
            meta: {
              breadCrumb: 'Añadir Engresos',
            },
          },
          {
            path: 'quick-assign',
            name: 'expenses.quick-assign',
            component: ExpensesQuickAssignment,
            meta: {
              breadCrumb: 'Listado de Facturas',
            },
          },
          {
            path: ':id/edit',
            name: 'expenses.edit',
            component: ExpenseCreate,
          },
          {
            path: 'all-expenses',
            name: 'expenses.all-expenses',
            component: ExpensesAll,
          },
        ],
      },

      //cuentas
      {
        path: 'accounts',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Transacciones',
        },
        children: [
          {
            path: '/',
            name: 'account.index',
            component: AccountsIndex,
          },
          {
            path: 'create',
            name: 'account.create',
            component: AccountsCreate,
          },
        ],
      },

      // Treasuries
      {
        path: 'treasury-accounts',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Transacciones',
        },
        children: [
          {
            path: '/',
            name: 'treasury_accounts.index',
            component: TreasuryAccountsIndex,
          },
          {
            path: 'create',
            name: 'treasury_accounts.create',
            component: TreasuryAccountCreate,
            meta: {
              breadCrumb: 'Agregar transacción',
            },
          },
          {
            path: 'upload/:id',
            name: 'treasury_accounts.upload',
            component: TreasuryAccountUploadTransactions,
            meta: {
              breadCrumb: 'Importar transacción',
            },
          },
          {
            path: 'general-public-invoice-treasury',
            name: 'general-public-invocie-treasury.index',
            component: TreasuryGeneralPublicInvoice,
            meta: {
              breadCrumb: 'Público General',
            },
          },
          {
            path: 'relate-invoices/:type/:id',
            name: 'treasury_accounts.relate_invoices',
            component: TreasuryAccountRelateInvoices,
          },
          {
            path: 'conciliations/:type/:id',
            name: 'treasury_accounts.conciliations',
            component: TreasuryAccountConciliations,
          },
        ],
      },

      {
        path: 'manual',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Carga manual',
        },
        children: [
          {
            path: 'upload',
            name: 'manual.upload',
            component: UploadCFDI,
            meta: {
              breadCrumb: 'Importar cfdi',
            },
          },
        ],
      },

      // Retenciones
      {
        path: 'retenciones',
        // name: 'retenciones.index',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Pagos y Retenciones',
        },
        children: [
          {
            path: '/',
            component: RetencionesIndex,
          },
          {
            path: ':id',
            name: 'retenciones.view',
            component: RetencionesView,
            meta: {
              breadCrumb: 'Retención',
            },
          },
        ],
      },

      // Customer Prod Serv Groups
      {
        path: 'customer-prod-serv-groups',
        name: 'customer-prod-serv-groups.index',
        component: CustomerProdServGroups,
      },

      // Concept Categories
      {
        path: 'concept-categories',
        name: 'concept-categories.index',
        component: LayoutBasic,
        meta: {
          breadCrumb: 'Categorias',
        },
        children: [
          {
            path: '/',
            component: ConceptCategoryIndex,
          },
        ],
      },

      {
        path: 'concept-categories-personal',
        name: 'concept-categories-personal.index',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Categorias',
        },
        children: [
          {
            path: '/',
            component: ConceptCategoryPersonal,
          },
        ],
      },
      // RATE TABLES
      {
        path: 'rate-tables',
        component: LayoutBasic,
        meta: {
          breadCrumb: 'Tablas de tarifas',
        },
        children: [
          {
            path: '/',
            component: RateTablesIndex,
            children: [
              {
                path: ':regime',
                name: 'rate-tables.table-regime',
                component: RateTablesTableRegime,
                meta: {
                  breadCrumb: 'Tarifas',
                },
              },
              {
                path: 'generate-year-tables',
                name: 'rate-tables.generate-year-tables',
                component: RateTablesGenerateYearTables,
                meta: {
                  breadCrumb: 'Agregar año',
                },
              },
            ],
          },
        ],
      },
      // {
      //   path: 'rate-tables',
      //   component: RateTablesIndex,
      //   meta: {
      //     breadCrumb: 'Tablas de tarifas',
      //   },
      //   children: [
      //     {
      //       path: ':regime',
      //       name: 'rate-tables.table-regime',
      //       component: RateTablesTableRegime,
      //       meta: {
      //         breadCrumb: 'Tarifas',
      //       },
      //     },
      //     {
      //       path: ' generate-year-tables',
      //       name: 'rate-tables.generate-year-tables',
      //       component: RateTablesGenerateYearTables,
      //       meta: {
      //         breadCrumb: 'Agregar año',
      //       },
      //     },
      //   ],
      // },

      //Tax Return
      {
        path: 'tax-returns',
        component: LayoutCustomerSelectTwo,
        meta: {
          breadCrumb: 'Declaración',
        },
        children: [
          {
            path: '/',
            component: TaxReturnIndex, //Dashboard,
            name: 'tax-return.index',
          },
          {
            path: 'create',
            name: 'tax-return.create',
            component: CreateTaxReturn,
            meta: {
              breadCrumb: 'Agregar declaración',
            },
          },
          {
            path: 'show/:id',
            name: 'tax-return.show',
            component: CreateTaxReturn,
          },
          {
            path: 'tax-losses',
            name: 'tax-return.tax-losses',
            component: TaxLosses,
            meta: {
              breadCrumb: 'Resumen declaración',
            },
          },
          {
            path: 'tax-losses',
            component: TaxLossDetails,
            meta: {
              breadCrumb: 'Resumen declaración',
            },
            children: [
              {
                path: ':id',
                name: 'tax-return.tax-loss.details',
                component: TaxLossDetails,
                meta: {
                  breadCrumb: 'Detalles declaración',
                },
              },
            ],
          },
        ],
      },
      /*{
                path: 'tax-returns/create',
                name: 'tax-return.create',
                component: CreateTaxReturn,
            },
            {
                path: 'tax-returns/show/:id',
                name: 'tax-return.show',
                component: CreateTaxReturn,
            },
            {
                path: 'tax-returns/tax-losses',
                name: 'tax-return.tax-losses',
                component: TaxLosses,
            },
            {
                path: 'tax-returns/tax-losses/:id',
                name: 'tax-return.tax-loss.details',
                component: TaxLossDetails,
            },*/

      // Reports
      {
        path: 'reports',
        component: ReportLayout,
        children: [
          {
            path: 'sales',
            component: SalesReports,
          },
          {
            path: 'expenses',
            component: ExpensesReport,
          },
          {
            path: 'profit-loss',
            component: ProfitLossReport,
          },
          {
            path: 'taxes',
            component: TaxReport,
          },
        ],
      },

      // Settings
      {
        path: 'settings',
        component: SettingsLayout,
        meta: {
          breadCrumb: 'Configuración',
        },
        children: [
          {
            path: 'company-info',
            name: 'company.info',
            component: CompanyInfo,
            meta: {
              breadCrumb: 'Información de la empresa',
            },
          },
          {
            path: 'customization',
            name: 'customization',
            component: Customization,
          },
          {
            path: 'user-profile',
            name: 'user.profile',
            component: UserProfile,
            meta: {
              breadCrumb: 'Configuración de la cuenta',
            },
          },
          {
            path: 'preferences',
            name: 'preferences',
            component: Preferences,
          },
          {
            path: 'tax-types',
            name: 'tax.types',
            component: TaxTypes,
          },
          {
            path: 'expense-category',
            name: 'expense.category',
            component: ExpenseCategory,
            meta: {
              breadCrumb: 'Categorías de gastos',
            },
          },
          {
            path: 'mail-configuration',
            name: 'mailconfig',
            component: MailConfig,
          },
          {
            path: 'notifications',
            name: 'notifications',
            component: Notifications,
          },
          {
            path: 'update-app',
            name: 'updateapp',
            component: UpdateApp,
          },
        ],
      },
    ],
  },

  // Customer dashboard routes
  {
    path: '/customer',
    component: LayoutCustomer,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'customer_dashboard.dashboard.index',
        component: CustomerDashboardIndex,
      },
      {
        path: 'certificates',
        name: 'customer_dashboard.certificates.index',
        component: CustomerCertificatesIndex,
      },
      {
        path: 'tax-returns',
        name: 'customer_dashboard.tax-returns.index',
        component: CustomerTaxReturnIndex,
      },
      {
        path: 'tax-returns/:id',
        name: 'customer_dashboard.tax-returns.details',
        component: CustomerTaxReturnDetails,
      },
    ],
  },

  {
    path: '/ejecutivo',
    component: {
      render: (c) => c('router-view'),
    },
    meta: { requiresAuth: true },
    children: [
      {
        path: 'customers',
        component: LayoutBasic,
        meta: { breadCrumb: 'Clientes' },
        children: [
          {
            path: 'pending',
            name: 'pending',
            component: PendingIndex,
            meta: {
              breadCrumb: 'Pendientes del mes',
            },
          },
        ],
      },
      {
        path: 'dashboard',
        name: 'customer_dashboard.dashboard.index',
        component: CustomerDashboardIndex,
      },
      {
        path: 'certificates',
        name: 'customer_dashboard.certificates.index',
        component: CustomerCertificatesIndex,
      },
      {
        path: 'tax-returns',
        name: 'customer_dashboard.tax-returns.index',
        component: CustomerTaxReturnIndex,
      },
      {
        path: 'tax-returns/:id',
        name: 'customer_dashboard.tax-returns.details',
        component: CustomerTaxReturnDetails,
      },
    ],
  },
  {
    path: '/supervisor',
    component: LayoutCustomer,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: 'customer_dashboard.dashboard.index',
        component: CustomerDashboardIndex,
      },
      {
        path: 'certificates',
        name: 'customer_dashboard.certificates.index',
        component: CustomerCertificatesIndex,
      },
      {
        path: 'tax-returns',
        name: 'customer_dashboard.tax-returns.index',
        component: CustomerTaxReturnIndex,
      },
      {
        path: 'tax-returns/:id',
        name: 'customer_dashboard.tax-returns.details',
        component: CustomerTaxReturnDetails,
      },
    ],
  },
  //  DEFAULT ROUTE
  { path: '*', component: NotFoundPage },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  linkActiveClass: 'active',
})

const loadCustomer = async (id) => {
  let customer = await new Promise((resolve, reject) => {
    window.axios
      .get(`/api/customers/${id}/edit`)
      .then((response) => {
        resolve(response)
      })
      .catch((err) => {
        reject(err)
      })
  })
  console.log(customer)
}

router.beforeEach((to, from, next) => {
  //loadCustomer(13);

  //  Redirect if not authenticated on secured routes
  if (to.matched.some((m) => m.meta.requiresAuth)) {
    if (!store.getters['auth/isAuthenticated']) {
      return next('/login')
    }
  }
  console.log(
    to.matched.some((m) => m.meta.redirectIfAuthenticated) &&
      store.getters['auth/isAuthenticated']
  )
  console.log('estoy aqui1')

  if (
    to.matched.some((m) => m.meta.redirectIfAuthenticated) &&
    store.getters['auth/isAuthenticated']
  ) {
    console.log('estoy aqui2')

    if (store.getters['auth/userType'] == 'admin') {
      return next('/general/customers/pending')
    }
    if (store.getters['auth/userType'] == 'customer') {
      return next('/customer/dashboard')
    }
    if (store.getters['auth/userType'] == 'supervisor') {
      return next('/supervisor/dashboard')
    }
    if (store.getters['auth/userType'] == 'ejecutivo') {
      return next('/general/customers/pending')
    }
  }

  return next()
})

export default router
