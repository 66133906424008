var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", [
        _c("div", { staticClass: "card p-2" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "table-primary" }, [
                  _c(
                    "th",
                    { staticClass: "text-center", attrs: { colspan: "2" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.isr")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("revenue")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.revenue_perceived")) +
                            "\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: _vm.taxReturn.amounts.revenue },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.revenue
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.revenue
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.revenue.invoices,
                                  totals: _vm.taxReturn.tables.revenue.totals,
                                  title: "tax_returns.revenue_perceived",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taxReturnBlind
                  ? _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.$t("tax_returns.tax_return_blind_35_percent")
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxReturn.amounts.deductions },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.taxReturnBlind
                  ? _c("tr", [
                      _c("td", [_vm._v("Deducción impuesto Predial")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxReturn.amounts.predio },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm.taxReturnBlind
                  ? _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("deductions")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("tax_returns.deductions")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.deductions,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.displayTables.deductions && !_vm.taxReturnBlind
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.deductions
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.deductions.invoices,
                                  totals:
                                    _vm.taxReturn.tables.deductions.totals,
                                  title: "tax_returns.deductions",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(_vm._s(_vm.$t("tax_returns.taxable_base"))),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.taxable_base },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("causado")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.isr_caused")) +
                            "\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: _vm.taxReturn.amounts.isr_caused },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.causado
                  ? _c("tr", [
                      _c("td", { attrs: { colspan: "2" } }, [
                        _c("div", { staticClass: "table-responsive" }, [
                          _c("table", { staticClass: "table table-bordered" }, [
                            _c("tbody", [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t("rate_tables.lower_limit")
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c("money-text", {
                                      attrs: {
                                        amount:
                                          _vm.taxReturn.amounts.lower_limit,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t("tax_returns.rate_on_surplus")
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "text-right" }, [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.taxReturn.amounts.rate_on_surplus
                                      ) +
                                      " %\n                        "
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.$t("tax_returns.marginal_amount")
                                      ) +
                                      "\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c("money-text", {
                                      attrs: {
                                        amount:
                                          _vm.taxReturn.amounts.marginal_amount,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(_vm.$t("rate_tables.fixed_fee")) +
                                      "\n                      "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  { staticClass: "text-right" },
                                  [
                                    _c("money-text", {
                                      attrs: {
                                        amount: _vm.taxReturn.amounts.fixed_fee,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.isr_withheld")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_withheld },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.isr_in_charge")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.isr_in_charge },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.update")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.isr_update_value,
                          expression: "taxReturn.amounts.isr_update_value",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.isr_update_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacionISR.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "isr_update_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.surcharges")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.isr_surcharge_value,
                          expression: "taxReturn.amounts.isr_surcharge_value",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.isr_surcharge_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacionISR.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "isr_surcharge_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", { staticClass: "clickable" }, [
                  _c("td", [_vm._v("Total de contribuciones")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.total_contribuciones,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleModalCompensaciones()
                      },
                    },
                  },
                  [
                    _c("td", [_vm._v("Compensaciones")]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: { amount: _vm.taxReturn.amounts.isr_caused },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.isr_total")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.total_isr },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "card p-2" }, [
          _c("div", { staticClass: "table-responsive" }, [
            _c("table", { staticClass: "table table-bordered" }, [
              _c("thead", [
                _c("tr", { staticClass: "table-primary" }, [
                  _c(
                    "th",
                    { staticClass: "text-center", attrs: { colspan: "2" } },
                    [
                      _vm._v(
                        "\n              " +
                          _vm._s(_vm.$t("tax_returns.iva")) +
                          "\n            "
                      ),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("tbody", [
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("revenueAndOtherIncomes")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(" "),
                        _vm._v(
                          "\n              IVA cobrado a la tada del 16%\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount: _vm.taxReturn.amounts.revenue_iva_charged,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.revenueAndOtherIncomes
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.revenue
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.revenue.invoices,
                                  totals: _vm.taxReturn.tables.revenue.totals,
                                  title: "tax_returns.revenue",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.taxReturn.tables.other_incomes
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.other_incomes.invoices,
                                  totals:
                                    _vm.taxReturn.tables.other_incomes.totals,
                                  title: "tax_returns.other_incomes",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.iva_charged")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_charged },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.iva_detained")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_detained },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "tr",
                  {
                    staticClass: "clickable",
                    on: {
                      click: function ($event) {
                        return _vm.toggleDisplayTable("purchasesAndDeductions")
                      },
                    },
                  },
                  [
                    _c(
                      "td",
                      [
                        _c("font-awesome-icon", {
                          staticClass: "icon",
                          attrs: { icon: "arrow-down" },
                        }),
                        _vm._v(" "),
                        _vm._v(
                          "\n              IVA Acreditable / Pagado por compras y gastos\n            "
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-right" },
                      [
                        _c("money-text", {
                          attrs: {
                            amount: _vm.taxReturn.amounts.iva_creditable,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.displayTables.purchasesAndDeductions
                  ? _c("tr", [
                      _c(
                        "td",
                        { attrs: { colspan: "2" } },
                        [
                          _vm.taxReturn.tables.deductions
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.deductions.invoices,
                                  totals:
                                    _vm.taxReturn.tables.deductions.totals,
                                  title: "tax_returns.deductions",
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.taxReturn.tables.purchases
                            ? _c("tax-return-invoices-table", {
                                attrs: {
                                  invoices:
                                    _vm.taxReturn.tables.purchases.invoices,
                                  totals: _vm.taxReturn.tables.purchases.totals,
                                  title: "tax_returns.purchases",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.amount_in_charge")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: {
                          amount: _vm.taxReturn.amounts.amount_in_charge,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.iva_in_charge")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.iva_in_charge },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.update")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.taxReturn.amounts.iva_update_value,
                          expression: "taxReturn.amounts.iva_update_value",
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.iva_update_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacionISR.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "iva_update_value",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [
                    _vm._v(
                      "\n              " +
                        _vm._s(_vm.$t("tax_returns.surcharges")) +
                        "\n            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticClass: "text-right" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.taxReturn.amounts.iva_surcharge_value,
                          expression: "taxReturn.amounts.iva_surcharge_value",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: { type: "number" },
                      domProps: {
                        value: _vm.taxReturn.amounts.iva_surcharge_value,
                      },
                      on: {
                        keydown: _vm.onKeyDown,
                        keyup: function ($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          )
                            return null
                          return _vm.actualizacionISR.apply(null, arguments)
                        },
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.taxReturn.amounts,
                            "iva_surcharge_value",
                            _vm._n($event.target.value)
                          )
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ]),
                _vm._v(" "),
                _c("tr", [
                  _c("td", [_vm._v("Total a pagar de IVA")]),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-right" },
                    [
                      _c("money-text", {
                        attrs: { amount: _vm.taxReturn.amounts.total_iva },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }