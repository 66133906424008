import * as types from './mutation-types'

export const createTreasuryAccount = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/treasury-accounts`, data)
      .then(response => resolve(response))
      .catch(err => reject(err));
  })
}

export const fetchTreasuryAccounts = ({commit}, params) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/treasury-accounts`, {params})
    .then((response) => {
      commit(types.SET_TREASURY_ACCOUNTS, response.data.data)
      resolve(response);
    })
    .catch(err => reject(err));
  });
}

// Related invoices
export const clearDataToRelateToTreasuryAccount = ({commit}) => {
  commit(types.SET_INVOICE_TO_RELATE, null)
}
export const setDataToRelateToTreasuryAccount = ({commit}, data) => {
  commit(types.SET_INVOICE_TO_RELATE, data)
}
export const relateInvoiteToTreasuryAccount = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/treasury-accounts/${data.treasury_account_id}/relate-invoice`, data)
      .then(response => resolve(response))
      .catch(err => reject(err));
  });
}
export const stopRelateInvoiteToTreasuryAccount = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios.post(`/api/treasury-accounts/${data.treasury_account_id}/stop-relate-invoice`, data)
      .then(response => resolve(response))
      .catch(err => reject(err));
  });
}
export const fetchTreasuryAccountRelatedInvoices = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios.get(
      `/api/treasury-accounts/${data.treasuryAccountId}/related-invoices`,
      {
        params: data.params
      }
    )
      .then(response => resolve(response))
      .catch(err => reject(err));
  });
}

export const fetchTransaction = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios.get(`/api/treasury-accounts/${id}`)
      .then(response => resolve(response.data))
      .catch(err => reject(err));
  });
}

export const uploadTransactionsFile = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/treasury-accounts/upload', data)
      .then(resolve)
      .catch(reject)
  })
}

export const uploadAccount = (store, data) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post('/api/treasury-accounts/account/create', data)
      .then(resolve)
      .catch(reject)
  })
}


export const toggleGeneralPublicInvoice = (store, id) => {
  return new Promise((resolve, reject) => {
    window.axios
      .post(`/api/treasury-accounts/${id}/toggle-general-public-invoice`, {})
      .then(resolve)
      .catch(reject)
  })
}
