var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pt-4 pb-4 pl-4 pr-4" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.savePaidDocument()
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("invoices.date")))]),
            _vm._v(" "),
            _c("base-date-picker", {
              attrs: {
                "calendar-button": true,
                invalid: _vm.$v.form.payment_date.$error,
                "calendar-button-icon": "calendar",
                typeable: true,
              },
              on: {
                change: function ($event) {
                  return _vm.$v.form.payment_date.$touch()
                },
              },
              model: {
                value: _vm.form.payment_date,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "payment_date", $$v)
                },
                expression: "form.payment_date",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.payment_date.$error
              ? _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("validation.required")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-group" },
          [
            _c("label", [_vm._v(_vm._s(_vm.$t("invoices.document_type")))]),
            _vm._v(" "),
            _c("base-select", {
              attrs: {
                options: _vm.paidDocumentOptions,
                "allow-empty": false,
                "show-labels": false,
                placeholder: _vm.$t("invoices.document_type"),
                "track-by": "name",
                label: "description",
              },
              model: {
                value: _vm.form.document_type,
                callback: function ($$v) {
                  _vm.$set(_vm.form, "document_type", $$v)
                },
                expression: "form.document_type",
              },
            }),
            _vm._v(" "),
            _vm.$v.form.document_type.$error
              ? _c("div", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("validation.required")) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("label", [_vm._v(_vm._s(_vm.$t("invoices.amount")))]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "base-input" },
            [
              _c(
                "money",
                _vm._b(
                  {
                    staticClass: "input-field",
                    on: {
                      input: function ($event) {
                        return _vm.$v.form.amount.$touch()
                      },
                    },
                    model: {
                      value: _vm.form.amount,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "amount", $$v)
                      },
                      expression: "form.amount",
                    },
                  },
                  "money",
                  _vm.customerCurrency,
                  false
                )
              ),
              _vm._v(" "),
              _vm.$v.form.amount.$error
                ? _c("div", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("validation.required")) +
                        "\n        "
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-right" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "submit" } },
            [
              _vm._v(
                "\n        " + _vm._s(_vm.$t("invoices.save")) + "\n      "
              ),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }