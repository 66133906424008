<template>
  <div class="main-content">
    <div class="page-header">
      <h3 class="page-title">
        {{ $t('treasury_accounts.relate') }}
        <span v-if="typeRelate">
          {{ $t(`treasury_accounts.${typeRelate}`) }}
        </span>
      </h3>
    </div>

    <div v-if="transaction" class="row">
      <div v-if="typeRelate=='payments'" class="col col-md-4 offset-md-8 text-right">
        <strong>{{ $t('treasury_accounts.transaction_amount') }}</strong>
        <strong v-html="$utils.formatMoney(transaction.payments_amount, defaultCurrency)" />
        <br>
        <strong>{{ $t('treasury_accounts.transaction_related') }}</strong>
        <strong v-html="$utils.formatMoney(transaction.payments_completed, defaultCurrency)" />
        <br>
        <strong>{{ $t('treasury_accounts.remainder') }}</strong>
        <strong v-html="$utils.formatMoney(remainder, defaultCurrency)" />
      </div>
      <div v-if="typeRelate=='expenses'" class="col col-md-4 offset-md-8 text-right">
        <strong>{{ $t('treasury_accounts.transaction_amount') }}</strong>
        <strong v-html="$utils.formatMoney(transaction.expenses_amount, defaultCurrency)" />
        <br>
        <strong>{{ $t('treasury_accounts.transaction_related') }}</strong>
        <strong v-html="$utils.formatMoney(transaction.expenses_completed, defaultCurrency)" />
        <br>
        <strong>{{ $t('treasury_accounts.remainder') }}</strong>
        <strong v-html="$utils.formatMoney(remainder, defaultCurrency)" />
      </div>
    </div>

    <!-- Related invoices table -->
    <h3>{{ $t('treasury_accounts.related_invoices') }}</h3>
    <table-component
      ref="tableRelatedInvoices"
      :show-filter="false"
      :data="getRelatedInvoices"
      :filter-no-results="$t('general.filterNoResults')"
      :is-invoices-list="true"
      table-class="table"
    >
      <table-column :label="$t('treasury_accounts.date')" show="invoice_date" />
      <table-column :label="$t('invoices.invoice_number')" show="invoice_number" />
      <table-column v-if="typeRelate=='payments'" :label="$t('payments.customer')" show="receiver_name" />
      <table-column v-if="typeRelate=='payments'" :label="$t('payments.customer_rfc')" show="receiver_rfc" />
      <table-column v-if="typeRelate=='expenses'" :label="$t('expenses.provider')" show="issuing_name" />
      <table-column v-if="typeRelate=='expenses'" :label="$t('expenses.provider_rfc')" show="issuing_rfc" />
      <table-column :label="$t('treasury_accounts.related_amount')" sort-as="total">
        <template slot-scope="row">
          <span>{{ $t('treasury_accounts.related') }}</span>
          <div
            v-html="$utils.formatMoney(row.invoice_related.amount, defaultCurrency)"
          />
        </template>
      </table-column>
      <table-column :label="$t('treasury_accounts.remainder')" sort-as="total">
        <template slot-scope="row">
          <span>{{ $t('treasury_accounts.remainder') }}</span>
          <div
            v-html="$utils.formatMoney(row.total - row.related_amount, defaultCurrency)"
          />
        </template>
      </table-column>
      <table-column :label="$t('invoices.action')">
        <template slot-scope="row">
          <span>{{ $t('invoices.action') }}</span>
          <button class="table-button" @click="stopRelateInvoice(row)">
            {{ $t('treasury_accounts.stop_relate') }}
          </button>
        </template>
      </table-column>
    </table-component>

    <!-- Invoices to relate table -->
    <div class="row">
      <div class="col col-md-8">
        <h3>{{ $t('treasury_accounts.invoices_to_relate') }}</h3>
      </div>
      <div class="col col-md-4">
        <div class="form-group">
          <label>
            {{ $t('treasury_accounts.name_or_rfc') }}
          </label>
          <base-input
            v-model="filters.nameOrRFC"
            type="text"
          />
        </div>
      </div>
    </div>
    <table-component
      ref="tableInvoices"
      :show-filter="false"
      :data="getInvoices"
      :filter-no-results="$t('general.filterNoResults')"
      :is-invoices-list="true"
      table-class="table"
    >
      <table-column :label="$t('treasury_accounts.date')" show="invoice_date" />
      <table-column :label="$t('invoices.invoice_number')" show="invoice_number" />
      <table-column v-if="typeRelate=='payments'" :label="$t('payments.customer')" show="receiver_name" />
      <table-column v-if="typeRelate=='payments'" :label="$t('payments.customer_rfc')" show="receiver_rfc" />
      <table-column v-if="typeRelate=='expenses'" :label="$t('expenses.provider')" show="issuing_name" />
      <table-column v-if="typeRelate=='expenses'" :label="$t('expenses.provider_rfc')" show="issuing_rfc" />
      <table-column :label="$t('invoices.total')" sort-as="total">
        <template slot-scope="row">
          <span>{{ $t('invoices.total') }}</span>
          <div
            v-html="$utils.formatMoney(row.total, defaultCurrency)"
          />
        </template>
      </table-column>
      <table-column :label="$t('treasury_accounts.remainder')" sort-as="total">
        <template slot-scope="row">
          <span>{{ $t('treasury_accounts.remainder') }}</span>
          <div
            v-html="$utils.formatMoney(row.total - row.related_amount, defaultCurrency)"
          />
        </template>
      </table-column>
      <table-column :label="$t('invoices.action')">
        <template slot-scope="row">
          <span>{{ $t('invoices.action') }}</span>
          <button class="table-button" @click="relateInvoice(row)">
            {{ $t('invoices.relate') }}
          </button>
        </template>
      </table-column>
    </table-component>

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    filters: {
      nameOrRFC: '',
    },
    typeRelate: null,
    treasuryAccountId: null,
    transaction: null,
  }),
  computed: {
    ...mapGetters('currency', [
      'defaultCurrency'
    ]),
    ...mapGetters('CurrentCustomer', [
      'currentCustomer',
    ]),
    remainder() {
      if (this.typeRelate == 'payments') {
        return this.transaction.payments_amount - this.transaction.payments_completed
      }
      return this.transaction.expenses_amount - this.transaction.expenses_completed
    },
  },
  watch: {
    filters: {
      handler: 'setFilters',
      deep: true
    }
  },
  created () {
    console.log('estos son los paramd',this.$route.params)

    if ( !this.currentCustomer ) {
      this.$router.push('/general/customers');
      return;
    }
    console.log('estos son los paramd',this.$route.params)
    this.typeRelate = this.$route.params.type;
    this.treasuryAccountId = this.$route.params.id;


  },
  mounted () {
    window.hub.$on('invoice-related', () => {
      this.loadTransaction();
      this.refreshInvoicesTable();
      this.refreshRelatedInvoicesTable();
    });
    this.loadTransaction();
  },
  methods: {
    ...mapActions('invoice', [
      'fetchInvoices',
    ]),
    ...mapActions('modal', [
      'openModal',
    ]),
    ...mapActions('treasuryAccounts', [
      'setDataToRelateToTreasuryAccount',
      'stopRelateInvoiteToTreasuryAccount',
      'fetchTreasuryAccountRelatedInvoices',
      'fetchTransaction',
    ]),
    async getInvoices ({page, sort}) {
      const params = {
        customer_id: this.currentCustomer.id,
        type: (this.typeRelate == 'expenses') ? 'expenses': 'receipts',
        related_amount: true,
        orderByField: sort.fieldName || 'invoice_date',
        orderBy: sort.order || 'asc',
        page,
      }
      if (this.filters.nameOrRFC) {
        params.name_or_rfc = this.filters.nameOrRFC;
      }

      const response = await this.fetchInvoices(params);

      return {
        data: response.data.invoices.data,
        pagination: {
          totalPages: response.data.invoices.last_page,
          currentPage: response.data.invoices.current_page,
          count: response.data.invoices.scount
        }
      };
    },
    async getRelatedInvoices ({page, sort}) {
      const data = {
        treasuryAccountId: this.treasuryAccountId,
        params: {
          orderByField: sort.fieldName || 'invoice_date',
          orderBy: sort.order || 'asc',
          page,
        }
      };
      const response = await this.fetchTreasuryAccountRelatedInvoices(data);

      return {
        data: response.data.data,
        pagination: {
          totalPages: response.data.last_page,
          currentPage: response.data.current_page,
          count: response.data.scount
        }
      };
    },
    loadTransaction () {
      this.fetchTransaction(this.$route.params.id)
      .then(transaction => this.transaction = transaction);
    },
    relateInvoice (invoice) {
      this.setDataToRelateToTreasuryAccount({
        treasuryAccountId: this.treasuryAccountId,
        typeRelate: this.typeRelate,
        invoice: invoice,
      });
      this.openModal({
        title: this.$t('treasury_accounts.relate_invoice'),
        componentName: 'RelateInvoiceToTreasuryAccountModal'
      });
    },
    setFilters () {
      this.refreshInvoicesTable();
    },
    stopRelateInvoice(invoice) {
      this.stopRelateInvoiteToTreasuryAccount({
        treasury_account_id: this.treasuryAccountId,
        invoice_id: invoice.id,
        type_relate: this.typeRelate,
      })
      .then(() => {
        this.loadTransaction();
        this.refreshInvoicesTable();
        this.refreshRelatedInvoicesTable();
      })
      .catch(() => {
        window.toastr['error'](this.$t('treasury_accounts.errors.stop_relate_invoice'));
      });
    },
    refreshInvoicesTable () {
      this.$refs.tableInvoices.refresh();
    },
    refreshRelatedInvoicesTable () {
      this.$refs.tableRelatedInvoices.refresh();
    },
  }
}
</script>
