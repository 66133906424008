var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.taxReturn
    ? _c("div", [
        _vm.taxReturn.amounts
          ? _c("div", { staticClass: "card p-4" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", { staticClass: "table-primary" }, [
                      _c(
                        "th",
                        { staticClass: "text-center", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.isr")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("revenueCollected")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("tax_returns.revenue_collected")
                                ) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.revenue_collected,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.revenueCollected
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _vm.taxReturn.tables.general_public
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.general_public
                                          .invoices,
                                      totals:
                                        _vm.taxReturn.tables.general_public
                                          .totals,
                                      title: "tax_returns.general_public_sales",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.taxReturn.tables.individuals
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.individuals
                                          .invoices,
                                      totals:
                                        _vm.taxReturn.tables.individuals.totals,
                                      title: "tax_returns.individual_sales",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("purchases")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              Compras y Gastos pagados\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.taxReturn.amounts.purchases_subtotal,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.purchases
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _vm.taxReturn.tables.purchases
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.purchases.invoices,
                                      totals:
                                        _vm.taxReturn.tables.purchases.totals,
                                      title: "tax_returns.purchases",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Diferencia por gastos mayores a ingresos"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.amounts
                                  .excess_expenses_over_revenue,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              Diferencia de gastos mayores a ingresos de periodos anteriores\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.taxReturn.amounts
                                  .excess_expenses_previous_periods_value,
                              expression:
                                "\n                  taxReturn.amounts.excess_expenses_previous_periods_value\n                ",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value:
                              _vm.taxReturn.amounts
                                .excess_expenses_previous_periods_value,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            click: function ($event) {
                              return _vm.toggleModalExceso(
                                "Detalle de Exceso de Deducciones"
                              )
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "excess_expenses_previous_periods_value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("\n              Utilidad\n            "),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.taxable_base,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("determinado")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              ISR Determinado\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.isr_caused,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.determinado
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-active",
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("tax_returns.taxable_base")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .taxable_base,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("rate_tables.lower_limit")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .lower_limit,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "tax_returns.surplus_lower_limit"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .surplus_lower_limit,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "tax_returns.rate_on_surplus"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", { staticClass: "text-right" }, [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.taxReturn.amounts
                                                .rate_on_surplus
                                            ) +
                                            " %\n                      "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "tax_returns.marginal_amount"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .marginal_amount,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t("rate_tables.fixed_fee")
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts.fixed_fee,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("% de Reducción")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.taxReturn.amounts.condonation_percentage,
                              expression:
                                "taxReturn.amounts.condonation_percentage",
                            },
                          ],
                          attrs: {
                            id: "myNumber",
                            type: "number",
                            min: "0",
                            max: "100",
                          },
                          domProps: {
                            value: _vm.taxReturn.amounts.condonation_percentage,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "condonation_percentage",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("\n              %\n            "),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Monto de Redducción")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.amount_forgiven,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.isr_to_pay")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxReturn.amounts.isr_to_pay },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.update")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxReturn.amounts.isr_update_value,
                              expression: "taxReturn.amounts.isr_update_value",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.isr_update_value,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "isr_update_value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.surcharges")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxReturn.amounts.isr_surcharge_value,
                              expression:
                                "taxReturn.amounts.isr_surcharge_value",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.isr_surcharge_value,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "isr_surcharge_value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", { staticClass: "clickable" }, [
                      _c("td", [_vm._v("Total de contribuciones")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.amounts.total_contribuciones,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Subsidio Para el Empleo")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxReturn.amounts.employment_subsidy,
                              expression:
                                "taxReturn.amounts.employment_subsidy",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.employment_subsidy,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "employment_subsidy",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Monto Pagado con Anterioridad")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.taxReturn.amounts.amount_previously_paid,
                              expression:
                                "taxReturn.amounts.amount_previously_paid",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.amount_previously_paid,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "amount_previously_paid",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", { staticClass: "clickable" }, [
                      _c("td", [_vm._v("Total de contribuciones")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.total_ISR_payable,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.taxReturn.amounts
          ? _c("div", { staticClass: "card p-4" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", { staticClass: "table-primary" }, [
                      _c(
                        "th",
                        { staticClass: "text-center", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.$t("tax_returns.iva")) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("generalPublicSales")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "tax_returns.revenue_general_public_sales"
                                  )
                                ) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.taxReturn.amounts
                                    .revenue_general_public_sales,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.generalPublicSales
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _vm.taxReturn.tables.general_public
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.general_public
                                          .invoices,
                                      totals:
                                        _vm.taxReturn.tables.general_public
                                          .totals,
                                      title: "tax_returns.general_public_sales",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("individualSales")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t("tax_returns.revenue_individual_sales")
                                ) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.taxReturn.amounts
                                    .revenue_individual_sales,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.individualSales
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _vm.taxReturn.tables.individuals
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.individuals
                                          .invoices,
                                      totals:
                                        _vm.taxReturn.tables.individuals.totals,
                                      title: "tax_returns.individual_sales",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("purchases_16")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.$t(
                                    "tax_returns.purchases_and_expenses_paid_16_percent"
                                  )
                                ) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount:
                                  _vm.taxReturn.amounts
                                    .purchases_and_expenses_paid16,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.purchases_16
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _vm.taxReturn.tables.purchases
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.purchases.invoices,
                                      totals:
                                        _vm.taxReturn.tables.purchases.totals,
                                      title: "tax_returns.purchases",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.taxReturn.tables.tasa_0.invoices.length > 0
                      ? _c(
                          "tr",
                          {
                            staticClass: "clickable",
                            on: {
                              click: function ($event) {
                                return _vm.toggleDisplayTable("tasa_0")
                              },
                            },
                          },
                          [
                            _c(
                              "td",
                              [
                                _c("font-awesome-icon", {
                                  staticClass: "icon",
                                  attrs: { icon: "arrow-down" },
                                }),
                                _vm._v(
                                  "\n              Compras y gastos pagados 0%\n            "
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "td",
                              { staticClass: "text-right" },
                              [
                                _c("money-text", {
                                  attrs: {
                                    amount: _vm.taxReturn.amounts.actividades0,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.displayTables.tasa_0 &&
                    _vm.taxReturn.tables.tasa_0.invoices.length > 0
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "2" } },
                            [
                              _vm.taxReturn.tables.tasa_0
                                ? _c("tax-return-invoices-table", {
                                    attrs: {
                                      invoices:
                                        _vm.taxReturn.tables.tasa_0.invoices,
                                      title: "Compras y gastos pagados 0%",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("Proporcion utilizada conforme a la LIVA"),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxReturn.amounts.proportion_used_liva,
                              expression:
                                "taxReturn.amounts.proportion_used_liva",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: {
                            type: "number",
                            step: "0.01",
                            min: "0",
                            max: "1",
                          },
                          domProps: {
                            value: _vm.taxReturn.amounts.proportion_used_liva,
                          },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.taxReturn.amounts,
                                  "proportion_used_liva",
                                  $event.target.value
                                )
                              },
                              _vm.validateInput,
                            ],
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("IVA que te retuvieron")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxReturn.amounts.withheld_tax_value,
                              expression:
                                "taxReturn.amounts.withheld_tax_value",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.withheld_tax_value,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "withheld_tax_value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v("IVA por venta al público en general 2%"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.iva_general_public,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("% de Reducción")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.taxReturn.amounts.percentage_iva_reduction,
                              expression:
                                "taxReturn.amounts.percentage_iva_reduction",
                            },
                          ],
                          attrs: {
                            id: "myNumber",
                            type: "number",
                            min: "0",
                            max: "100",
                          },
                          domProps: {
                            value:
                              _vm.taxReturn.amounts.percentage_iva_reduction,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "percentage_iva_reduction",
                                $event.target.value
                              )
                            },
                          },
                        }),
                        _vm._v("\n              %\n            "),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Iva Cobrado")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.amounts
                                  .iva_caused_induvidual_sales,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("acreditable")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              " +
                                _vm._s(_vm.$t("tax_returns.iva_creditable")) +
                                "\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.iva_creditable,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.acreditable
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-info",
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(
                                              _vm.$t(
                                                "tax_returns.iva_paid_bimester"
                                              )
                                            ) +
                                            "\n                      "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .iva_paid_bimester,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "tr",
                                      {
                                        staticClass: "clickable",
                                        on: {
                                          click: function ($event) {
                                            return _vm.toggleDisplayTable(
                                              "proportion"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "td",
                                          [
                                            _c("font-awesome-icon", {
                                              staticClass: "icon",
                                              attrs: { icon: "arrow-down" },
                                            }),
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "tax_returns.proportion_of_iva_creditable"
                                                  )
                                                ) +
                                                "\n                      "
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "td",
                                          { staticClass: "text-right" },
                                          [
                                            _vm._v(
                                              "\n                        " +
                                                _vm._s(
                                                  _vm.taxReturn.amounts
                                                    .proportion_of_iva_creditable
                                                ) +
                                                " %\n                      "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.displayTables.proportion
                                      ? _c("tr", [
                                          _c(
                                            "td",
                                            { attrs: { colspan: "2" } },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "table-responsive",
                                                },
                                                [
                                                  _c(
                                                    "table",
                                                    {
                                                      staticClass:
                                                        "table table-bordered table-secondary",
                                                    },
                                                    [
                                                      _c("tbody", [
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              "\n                                  Ingresos facturados clientes individuales\n                                  tasa 16% ↓\n                                "
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c("money-text", {
                                                                attrs: {
                                                                  amount:
                                                                    _vm
                                                                      .taxReturn
                                                                      .amounts
                                                                      .revenue_individual_sales,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("tr", [
                                                          _c("td", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "tax_returns.total_revenue"
                                                                )
                                                              )
                                                            ),
                                                          ]),
                                                          _vm._v(" "),
                                                          _c(
                                                            "td",
                                                            {
                                                              staticClass:
                                                                "text-right",
                                                            },
                                                            [
                                                              _c("money-text", {
                                                                attrs: {
                                                                  amount:
                                                                    _vm
                                                                      .taxReturn
                                                                      .amounts
                                                                      .total_revenue,
                                                                },
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        staticClass: "clickable",
                        on: {
                          click: function ($event) {
                            return _vm.toggleDisplayTable("cargo_favor")
                          },
                        },
                      },
                      [
                        _c(
                          "td",
                          [
                            _c("font-awesome-icon", {
                              staticClass: "icon",
                              attrs: { icon: "arrow-down" },
                            }),
                            _vm._v(
                              "\n              IVA a Cargo/Favor\n            "
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "td",
                          { staticClass: "text-right" },
                          [
                            _c("money-text", {
                              attrs: {
                                amount: _vm.taxReturn.amounts.iva_in_charge,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.displayTables.cargo_favor
                      ? _c("tr", [
                          _c("td", { attrs: { colspan: "2" } }, [
                            _c("div", { staticClass: "table-responsive" }, [
                              _c(
                                "table",
                                {
                                  staticClass:
                                    "table table-bordered table-info",
                                },
                                [
                                  _c("tbody", [
                                    _c("tr", [
                                      _c("td", [_vm._v("IVA Cobrado")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .total_iva_caused,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("td", [_vm._v("IVA Acreditable")]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        { staticClass: "text-right" },
                                        [
                                          _c("money-text", {
                                            attrs: {
                                              amount:
                                                _vm.taxReturn.amounts
                                                  .iva_creditable,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("IVA a Favor de Periodos Anteriores")]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.taxReturn.amounts
                                  .iva_credit_balance_previous_periods_value,
                              expression:
                                "\n                  taxReturn.amounts.iva_credit_balance_previous_periods_value\n                ",
                            },
                          ],
                          staticClass: "w-50",
                          attrs: { type: "number" },
                          domProps: {
                            value:
                              _vm.taxReturn.amounts
                                .iva_credit_balance_previous_periods_value,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleModalSaldo()
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "iva_credit_balance_previous_periods_value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Iva a Pagar")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: { amount: _vm.taxReturn.amounts.iva_pay },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.update")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.taxReturn.amounts.iva_update_value,
                              expression: "taxReturn.amounts.iva_update_value",
                            },
                          ],
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.iva_update_value,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "iva_update_value",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.$t("tax_returns.surcharges")) +
                            "\n            "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.taxReturn.amounts.iva_surcharge_value,
                              expression:
                                "taxReturn.amounts.iva_surcharge_value",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: { type: "number" },
                          domProps: {
                            value: _vm.taxReturn.amounts.iva_surcharge_value,
                          },
                          on: {
                            keydown: _vm.onKeyDown,
                            keyup: function ($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              )
                                return null
                              return _vm.actualizacion.apply(null, arguments)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.taxReturn.amounts,
                                "iva_surcharge_value",
                                _vm._n($event.target.value)
                              )
                            },
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [_vm._v("Total a pagar de IVA")]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.iva_total_pay,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(0),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.taxReturn.amounts
          ? _c("div", { staticClass: "card p-4" }, [
              _c("div", { staticClass: "table-responsive" }, [
                _c("table", { staticClass: "table table-bordered" }, [
                  _c("thead", [
                    _c("tr", { staticClass: "table-primary" }, [
                      _c(
                        "th",
                        { staticClass: "text-center", attrs: { colspan: "2" } },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm.$t("tax_returns.isr_detained_for_payroll")
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _vm._v(_vm._s(_vm.$t("tax_returns.isr_detained"))),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount:
                                _vm.taxReturn.amounts.isr_detained_payroll,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.employment_subsidy"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.employment_subsidy,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c("td", [
                        _vm._v(
                          _vm._s(_vm.$t("tax_returns.isr_detained_to_pay"))
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-right" },
                        [
                          _c("money-text", {
                            attrs: {
                              amount: _vm.taxReturn.amounts.isr_detained_to_pay,
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("tr", [
      _c("td"),
      _vm._v(" "),
      _c("td", { staticClass: "text-right" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }