<template>
  <div v-if="invoice" class="main-content">
    <div class="page-header">
      <h3 class="page-title">
        <span v-if="invoice.serie">{{ invoice.serie }} - </span>
        {{ invoice.invoice_number }}
      </h3>
    </div>

    <div class="card">
      <div class="p-2">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="table-responsive">
              <table class="table invoice-info">
                <tbody>
                  <tr>
                    <td>
                      <strong>{{ $t('invoices.serie') }}:</strong>
                      <br />
                      {{ invoice.serie }}
                    </td>
                    <td>
                      <strong>{{ $t('invoices.invoice_number') }}: </strong>
                      <br />
                      {{ invoice.invoice_number }}
                    </td>
                    <td>
                      <strong>UUID: </strong> <br />
                      {{ invoice.uuid }}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('invoices.payment_method') }}:</strong>
                      <br />
                      <payment-method-catalog :code="invoice.payment_method" />
                    </td>
                    <td>
                      <strong>{{ $t('invoices.way_to_pay') }}:</strong>
                      <br />
                      <way-to-pay-catalog :code="invoice.way_to_pay" />
                    </td>
                    <td>
                      <strong>{{ $t('invoices.cfdi_type') }}:</strong>
                      <br />
                      <cfdi-type-catalog :code="invoice.cfdi_type" />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <strong>{{ $t('invoices.date') }}</strong>
                      <br />
                      {{ invoice.invoice_date }}
                    </td>
                    <td>
                      <strong>{{ $t('invoices.stamped_date') }}</strong>
                      <br />
                      {{ invoice.stamped_date }}
                    </td>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div class="col-12 col-sm-3 text-center">
            <strong>{{ $t('invoices.issuing') }}</strong> <br />
            {{ invoice.issuing_rfc }} <br />
            {{ invoice.issuing_name }}
          </div>

          <div class="col-12 col-sm-3 text-center">
            <strong>{{ $t('invoices.receiver') }}</strong> <br />
            {{ invoice.receiver_rfc }} <br />
            {{ invoice.receiver_name }}
          </div>
        </div>
      </div>
    </div>

    <!-- Apply payment button -->
    <div class="row">
      <div class="col-7 text-right" />
      <div class="col-3 text-right">
        <button
          v-if="invoice.id_facturama != null"
          class="btn btn-primary"
          @click="descargarPDF(invoice.id_facturama)"
        >
          {{ $t('general.download_pdf') }} Facturama
        </button>
      </div>
      <div class="col-2 text-right">
        <button class="btn btn-primary" @click="showApplyPaymentModal()">
          {{ $t('invoices.apply_payment') }}
        </button>
      </div>
    </div>
    <!-- Transactions and paid documents -->
    <div class="card mt-2">
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th>{{ $t('invoices.date') }}</th>
              <th>{{ $t('invoices.account_statement.document_type') }}</th>
              <th>{{ $t('invoices.account_statement.amount_paid') }}</th>
              <th>{{ $t('invoices.remainder') }}</th>
              <th>{{ $t('invoices.percentage') }}</th>
              <th>{{ $t('invoices.iva') }}</th>
              <th>{{ $t('invoices.isr') }}</th>
              <!--               <th>{{ $t('invoices.in_tax_return') }}</th>
 -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="document in documents" :key="document.dom_id">
              <td>{{ document.date }}</td>
              <td>
                <router-link
                  v-if="document.link"
                  :to="document.link"
                  class="link"
                >
                  {{ document.type }}
                </router-link>
                <div v-else>{{ document.type }}</div>
              </td>
              <td>
                <money-text :amount="document.related_amount" />
              </td>
              <td>
                <money-text :amount="document.remainder" />
              </td>
              <td class="text-center">
                {{ document.percentage }}
                %
              </td>
              <td>
                <money-text :amount="document.iva" />
              </td>
              <td>
                <money-text :amount="document.isr" />
              </td>
              <!--               <td>
                <div class="text-center">
                  <input
                    v-model="document.in_tax_return"
                    type="checkbox"
                    @change="documentInTaxReturn(document)"
                  />
                </div>
              </td> -->
              <td>
                <button
                  v-if="document.sys_type == 'paid_document'"
                  class="btn btn-primary btn-small"
                  @click="deletePaidDocument(document)"
                >
                  <font-awesome-icon :icon="['fas', 'trash']" />
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- Apply categories button -->
    <div class="row">
      <div class="col-7 text-right" />
      <div class="col-3 text-right" />
      <div class="col-2 text-right">
        <!-- <button class="btn btn-primary" @click="showApplyPaymentModal()">
          Aplicar categorias
        </button> -->
        <button-category />
      </div>
    </div>
    <!-- List invoice items -->
    <div class="card">
      <div class="table-responsive p-2">
        <table>
          <thead>
            <tr class="text-uppercase">
              <th>{{ $t('invoices.item.title') }}</th>
              <th>{{ $t('invoices.item.description') }}</th>
              <th>{{ $t('invoices.item.clave_prod_serv') }}</th>
              <th class="text-center">{{ $t('invoices.item.quantity') }}</th>
              <th class="text-center amount-cell">
                <span v-if="isPayRoll">
                  {{ $t('invoices.complements.total_deductions') }}
                </span>
                <span v-else>{{ $t('invoices.item.amount') }}</span>
              </th>
              <th v-if="isPayRoll">
                {{ $t('invoices.complements.total_other_payments') }}
              </th>
              <th class="text-center amount-cell">
                <span v-if="isPayRoll">
                  {{ $t('invoices.complements.total_perceptions') }}
                </span>
                <span v-else>{{ $t('invoices.discount') }}</span>
              </th>
              <th class="text-center amount-cell">
                <span v-if="!isPayRoll">%</span>
              </th>
              <th class="text-center amount-cell">
                <span v-if="!isPayRoll">{{ $t('invoices.iva') }}</span>
              </th>
              <th class="text-center amount-cell">
                {{ $t('invoices.item.total') }}
              </th>
              <th class="text-center amount-cell">
                <input
                  :checked="categoriesSelect.length == concepts.length"
                  type="checkbox"
                  @change="checkCategoryAll()"
                />
              </th>
              <th>{{ $tc('invoices.category', 1) }}</th>
              <th class="text-center">
                {{ $tc('invoices.tax', 1) }}
                <input
                  ref="inpuestoCheckbox"
                  :checked="isInpuestoSelect()"
                  type="checkbox"
                  @change="checkInpuestoAll()"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in concepts">
              <tr :key="item.uid" class="border-top">
                <td>
                  <sub-string :string="item.name" :length="25" />
                </td>
                <td>
                  <sub-string :string="item.description" :length="30" />
                </td>
                <td>
                  {{ item.clave_prod_serv }}
                </td>
                <td class="text-center">
                  {{ item.quantity }}
                </td>
                <td class="text-center">
                  <span v-if="isPayRoll">
                    <money-text
                      :amount="
                        Number(invoice.complement_nomina.total_deductions)
                      "
                    />
                  </span>
                  <span v-else>
                    <money-text :amount="Number(item.total)" />
                  </span>
                </td>
                <td v-if="isPayRoll">
                  <money-text
                    :amount="
                      Number(invoice.complement_nomina.total_other_payments)
                    "
                  />
                </td>
                <td class="text-center">
                  <span v-if="isPayRoll">
                    <money-text
                      :amount="
                        Number(invoice.complement_nomina.total_perceptions)
                      "
                    />
                  </span>
                  <span v-else>
                    <money-text :amount="Number(item.discount_val)" />
                  </span>
                </td>
                <td class="text-center">
                  {{ parseFloat((item.iva * 100) / item.total).toFixed(2) }}
                </td>
                <td class="text-center">
                  <!-- <span v-if="!isPayRoll"> -->
                  <money-text :amount="Number(item.iva)" />
                  <!-- </span> -->
                </td>
                <td class="text-center">
                  <money-text :amount="Number(item.total) + Number(item.iva)" />
                </td>
                <td class="text-center">
                  <input
                    :checked="isCategorySelected(item)"
                    type="checkbox"
                    @change="checkCategory(item)"
                  />
                </td>
                <td class="text-center">
                  <concept-category :invoice-concept="item" />
                </td>
                <td>
                  <div class="text-center">
                    <!--                     v-if="item.can_add_to_tax_return"
 -->
                    <input
                      v-model="item.in_tax_return"
                      type="checkbox"
                      @change="inTaxReturn(item)"
                    />
                    <a
                      href="#/"
                      @click="
                        openModelComment(item.id, item.comments_in_tax_return)
                      "
                    >
                      <font-awesome-icon
                        icon="comment"
                        v-if="item.comments_in_tax_return == null"
                        class="right-icon text-secondary"
                      />
                      <font-awesome-icon
                        icon="comment"
                        v-if="item.comments_in_tax_return == ''"
                        class="right-icon text-secondary"
                      />
                      <font-awesome-icon
                        icon="comment"
                        v-if="item.comments_in_tax_return != null"
                        class="right-icon text-blue"
                      />
                    </a>

                    <div v-if="modelComment">
                      <transition name="model">
                        <div class="modal-mask">
                          <div class="modal-wrapper">
                            <div class="modal-dialog">
                              <div
                                class="modal-content"
                                style="width: 115%; font-size: 12px"
                              >
                                <div class="modal-header">
                                  <h4 class="modal-title">Comentarios</h4>
                                </div>
                                <div class="modal-body">
                                  <table>
                                    <tbody>
                                      <template
                                        v-for="item in comments_in_tax_return"
                                      >
                                        <tr>
                                          <td>{{ item.fecha }}</td>
                                          <td>{{ item.user }}</td>
                                          <td>{{ item.comentario }}</td>
                                        </tr>
                                      </template>
                                    </tbody>
                                  </table>
                                  <div class="form-group">
                                    <textarea
                                      type="text"
                                      class="form-control"
                                      v-model="comment"
                                    />
                                  </div>
                                  <br />
                                  <div align="center">
                                    <input type="hidden" v-model="id_comment" />
                                    <input
                                      type="button"
                                      class="btn btn-secondary btn-xs"
                                      @click="modelComment = false"
                                      value="Cancelar"
                                    />
                                    <input
                                      type="button"
                                      class="btn btn-success btn-xs"
                                      @click="commentInTaxReturn()"
                                      value="Guardar"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </transition>
                    </div>
                  </div>
                </td>
              </tr>
            </template>
            <!-- Totales nomina -->
            <tr v-if="isPayRoll">
              <td colspan="8"></td>
              <td>
                <strong>
                  {{ $t('invoices.complements.total_deductions') }}
                </strong>
              </td>
              <td class="text-right">
                <money-text
                  :amount="Number(invoice.complement_nomina.total_deductions)"
                />
              </td>
            </tr>
            <tr v-if="isPayRoll">
              <td colspan="8"></td>
              <td>
                <strong>
                  {{ $t('invoices.complements.total_other_payments') }}
                </strong>
              </td>
              <td class="text-right">
                <money-text
                  :amount="
                    Number(invoice.complement_nomina.total_other_payments)
                  "
                />
              </td>
            </tr>
            <tr v-if="isPayRoll">
              <td colspan="8"></td>
              <td>
                <strong>
                  {{ $t('invoices.complements.total_perceptions') }}
                </strong>
              </td>
              <td class="text-right">
                <money-text
                  :amount="Number(invoice.complement_nomina.total_perceptions)"
                />
              </td>
            </tr>

            <!-- Totales factura -->
            <tr v-if="!isPayRoll">
              <td colspan="8"></td>
              <td>
                <strong>{{ $t('invoices.sub_total') }}</strong>
              </td>
              <td class="text-right">
                <money-text :amount="Number(invoice.sub_total)" />
              </td>
            </tr>
            <tr v-if="!isPayRoll">
              <td colspan="8"></td>
              <td>
                <strong>{{ $t('invoices.discount') }}</strong>
              </td>
              <td class="text-right">
                <money-text :amount="Number(invoice.discount)" />
              </td>
            </tr>
            <tr v-if="!isPayRoll">
              <td colspan="8"></td>
              <td>
                <strong>{{ $t('invoices.iva') }}</strong>
              </td>
              <td class="text-right">
                <money-text :amount="Number(invoice.iva)" />
              </td>
            </tr>
            <tr>
              <td colspan="8"></td>
              <td>
                <strong>{{ $t('invoices.total') }}</strong>
              </td>
              <td class="text-right">
                <money-text :amount="Number(invoice.total)" />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- List taxes -->
    <div v-if="invoice.invoice_taxes.length" class="card p-2">
  <div class="table-responsive">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th class="text-center" style="width: 20%">{{ $t('invoices.tax_table.tax') }}</th>
          <th class="text-center" style="width: 20%">{{ $t('invoices.tax_table.type') }}</th>
          <th class="text-center" style="width: 20%">Factor</th>
          <th class="text-center" style="width: 20%">Tasa</th>
          <th class="text-center" style="width: 20%">{{ $t('invoices.tax_table.amount') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="tax in invoice.invoice_taxes" :key="tax.id">
          <td class="text-center">
            <tax-catalog :tax-code="tax.tax" />
          </td>
          <td class="text-center">{{ tax.type.toUpperCase() }}</td>
          <td class="text-center">{{ tax.factor == null ? '-' : tax.factor.toUpperCase() }}</td>
          <td class="text-center">{{ tax.rate == null ? '-' : tax.rate }}</td>
          <td class="text-center">
            <money-text :amount="Number(tax.amount)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>


    <!-- CFDI related -->
    <div v-if="invoice.related_invoices" class="card">
      <h5 class="complement-title">
        <c-relation-type :code="invoice.related_invoices.relation_type" />
      </h5>

      <table class="table invoice-info">
        <thead>
          <tr>
            <th class="text-center">{{ $t('invoices.uuid') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="uuid in invoice.related_invoices.invoices" :key="uuid">
            <td class="text-center">
              <uuid-link :uuid="uuid" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Complement payment -->
    <div v-if="invoice.complement_payments.length" class="card p-2">
      <h5 class="complement-title">
        {{ $t('invoices.complements.complement_payments') }}
      </h5>
      <div
        v-for="complementPayment in invoice.complement_payments"
        :key="complementPayment.id"
        class="mb-4"
      >
        <!-- Info on payment node -->
        <div class="table-responsive">
          <table class="invoice-info">
            <thead>
              <tr>
                <th class="text-center">
                  {{ $t('invoices.complements.payment_date') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.payment_method') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.currency') }}
                </th>
                <th class="text-center amount-cell-md">
                  {{ $t('invoices.complements.amount') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  {{ complementPayment.payment_date }}
                </td>
                <td class="text-center">
                  {{ complementPayment.payment_method }}
                </td>
                <td class="text-center">
                  {{ complementPayment.currency }}
                </td>
                <td class="text-right">
                  <money-text :amount="Number(complementPayment.amount)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- List of invoices related -->
        <div class="table-responsive mt-2 mb-4">
          <table class="table-bordered invoice-info">
            <thead>
              <tr>
                <th>{{ $t('invoices.complements.uuid') }}</th>
                <th>{{ $t('invoices.complements.serie') }}</th>
                <th>{{ $t('invoices.complements.invoice_number') }}</th>
                <th class="amount-cell">
                  {{ $t('invoices.complements.currency') }}
                </th>
                <th class="amount-cell">
                  {{ $t('invoices.complements.payment_method') }}
                </th>
                <th class="amount-cell">
                  {{ $t('invoices.complements.partiality') }}
                </th>
                <th class="text-center amount-cell-md">
                  {{ $t('invoices.complements.previous_balance') }}
                </th>
                <th class="text-center amount-cell-md">
                  {{ $t('invoices.complements.amount_payment') }}
                </th>
                <th class="text-center amount-cell-md">
                  {{ $t('invoices.complements.outstanding_balance') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="relatedInvoice in complementPayment.related_invoices"
                :key="relatedInvoice.id"
              >
                <td>
                  <uuid-link :uuid="relatedInvoice.uuid" />
                </td>
                <td>{{ relatedInvoice.serie }}</td>
                <td>{{ relatedInvoice.invoice_number }}</td>
                <td>{{ relatedInvoice.currency }}</td>
                <td>{{ relatedInvoice.payment_method }}</td>
                <td>{{ relatedInvoice.partiality }}</td>
                <td class="text-right">
                  <money-text
                    :amount="Number(relatedInvoice.previous_balance)"
                  />
                </td>
                <td class="text-right">
                  <money-text :amount="Number(relatedInvoice.amount_payment)" />
                </td>
                <td class="text-right">
                  <money-text
                    :amount="Number(relatedInvoice.outstanding_balance)"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Complement nomina -->
    <div v-if="invoice.cfdi_type == 'N'">
      <div v-if="invoice.complement_nomina" class="card p-2">
        <h5 class="complement-title">
          {{ $t('invoices.complements.complement_nomina') }}
        </h5>

        <div class="table-responsive">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-center">
                  {{ $t('invoices.complements.payment_date') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.payment_start_date') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.payment_end_date') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.total_deductions') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.total_other_payments') }}
                </th>
                <th class="text-center">
                  {{ $t('invoices.complements.total_perceptions') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">
                  {{ invoice.complement_nomina.payment_date }}
                </td>
                <td class="text-center">
                  {{ invoice.complement_nomina.payment_start_date }}
                </td>
                <td class="text-center">
                  {{ invoice.complement_nomina.payment_end_date }}
                </td>
                <td class="text-center">
                  <money-text
                    :amount="invoice.complement_nomina.total_deductions"
                  />
                </td>
                <td class="text-center">
                  <money-text
                    :amount="invoice.complement_nomina.total_other_payments"
                  />
                </td>
                <td class="text-center">
                  <money-text
                    :amount="invoice.complement_nomina.total_perceptions"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row">
          <!-- Percepciones -->
          <div class="col-12 col-md-6">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="text-center">
                      {{ $t('invoices.complements.perception') }}
                    </th>
                    <th class="text-center">
                      {{ $t('invoices.complements.amount') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Percepciones -->
                  <tr
                    v-for="perception in invoice.complement_nomina.perceptions"
                    :key="perception.id"
                  >
                    <td class="text-center">
                      <cfdi-perception-deduction-type
                        :code="perception.perception_type"
                        type="perception"
                      />
                    </td>
                    <td class="text-center">
                      <money-text :amount="perception.taxed_amount" />
                    </td>
                  </tr>
                  <!-- Otros pagos -->
                  <tr
                    v-for="otherPayment in invoice.complement_nomina
                      .other_payments"
                    :key="otherPayment.id"
                  >
                    <td class="text-center">
                      <cfdi-perception-deduction-type
                        :code="otherPayment.other_payment_type"
                        type="other_payment"
                      />
                    </td>
                    <td class="text-center">
                      <money-text :amount="otherPayment.amount" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- Deducciones -->
          <div class="col-12 col-md-6">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th class="text-center">
                      {{ $t('invoices.complements.deduction') }}
                    </th>
                    <th class="text-center">
                      {{ $t('invoices.complements.amount') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="deduction in invoice.complement_nomina.deductions"
                    :key="deduction.id"
                  >
                    <td class="text-center">
                      <cfdi-perception-deduction-type
                        :code="deduction.deduction_type"
                        type="deduction"
                      />
                    </td>
                    <td class="text-center">
                      <money-text :amount="deduction.amount" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Guid from 'guid'
import ConceptCategory from '../components/ConceptCategory'
import ButtonCategory from '../components/ButtonCategory'

import TaxCatalog from '../components/TaxCatalog'
import PaymentMethodCatalog from '../components/PaymentMethodCatalog'
import WayToPayCatalog from '../components/WayToPayCatalog'
import CfdiTypeCatalog from '../components/CfdiTypeCatalog'
import MoneyText from '../../components/base/MoneyText'
import UuidLink from '../components/UuidLink'
import CRelationType from '../components/CRelationType'
import CfdiPerceptionDeductionType from '../components/CfdiPerceptionDeductionType'

export default {
  components: {
    ConceptCategory,
    ButtonCategory,
    TaxCatalog,
    PaymentMethodCatalog,
    WayToPayCatalog,
    CfdiTypeCatalog,
    MoneyText,
    UuidLink,
    CRelationType,
    CfdiPerceptionDeductionType,
  },
  data: () => ({
    invoice: null,
    invoiceId: null,
    paidDocuments: [],
    id_comment: '',
    comments_in_tax_return: [],
    comment: '',
    modelComment: false,
    userName: '',
  }),
  computed: {
    ...mapGetters('conceptCategories', ['categoriesSelect']),
    documents() {
      let documents = []
      if (this.invoice) {
        const transactions = this.invoice.treasury_accounts.map(
          (transaction) => {
            return {
              id: transaction.id,
              dom_id: Guid.raw(),
              type: this.$t('invoices.paid_document_types.statement_account'),
              date: transaction.treasury_date,
              amount:
                transaction.payments_amount || transaction.expenses_amount,
              related_amount: transaction.transaction_related.amount,
              in_tax_return: transaction.transaction_related.in_tax_return,
              iva: transaction.transaction_related.iva,
              isr: transaction.transaction_related.isr,
              percentage: transaction.transaction_related.percentage.toFixed(2),
              remainder: 0,
              sys_type: 'transaction',
              link: null,
            }
          }
        )
        documents = documents.concat(transactions)
      }
      if (this.paidDocuments.length) {
        const paidDocuments = this.paidDocuments.map((paidDocument) => {
          let link = null
          if (paidDocument.related_invoice_id) {
            link = {
              name: 'invoices.view',
              params: { id: paidDocument.related_invoice_id },
            }
          }
          return {
            id: paidDocument.id,
            dom_id: Guid.raw(),
            type: this.$t(
              `invoices.paid_document_types.${paidDocument.document_type}`
            ),
            date: paidDocument.payment_date,
            amount: paidDocument.amount,
            related_amount: paidDocument.amount,
            in_tax_return: paidDocument.in_tax_return,
            iva: paidDocument.iva,
            isr: paidDocument.isr,
            percentage: paidDocument.percentage.toFixed(2),
            remainder: 0,
            sys_type: 'paid_document',
            link,
          }
        })
        documents = documents.concat(paidDocuments)
      }

      documents.sort(function (a, b) {
        return new Date(a.date).getTime() - new Date(b.date).getTime()
      })

      let total = this.invoice.total
      documents = documents.map((document) => {
        total -= document.related_amount
        const remainder = total
        document.remainder = remainder
        return document
      })

      return documents
    },
    concepts() {
      return this.invoice.items.map((item) => {
        return {
          ...item,
          uid: Guid.raw(),
        }
      })
    },
    iva() {
      if (!this.invoice) {
        return 0
      }
      const tax = this.invoice.invoice_taxes.filter((tax) => {
        return tax.tax == '002' && tax.type == 'traslado' && tax.amount > 0
      })
      if (tax.length) {
        return tax[0].amount
      }
      return 0
    },
    isPayRoll() {
      if (this.invoice) {
        return this.invoice.cfdi_type == 'N'
      }
      return false
    },
  },
  created() {
    this.invoiceId = this.$route.params.id
  },
  mounted() {
    this.fetchInvoice()
    this.fetchPaidDocuments()
    this.setInitialData()

    window.hub.$on('paid_document_added', () => {
      this.fetchPaidDocuments()
    })
    window.hub.$on('invoiceItemCategoryChanged', (data) => {
      this.fetchInvoice()
      this.fetchPaidDocuments()
    })
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == 'invoices.view') {
      this.invoiceId = to.params.id
      this.fetchInvoice()
      this.fetchPaidDocuments()
    }
    next()
  },
  methods: {
    ...mapActions('invoice', [
      'fetchViewInvoice',
      'saveInTaxReturn',
      'saveInTaxReturnAll',
      'setDataModalAddPaidDocument',
      'fetchInvoicePaidDocuments',
      'fetchDeletePaidDocument',
      'fetchDocumentSaveInTaxReturn',
      'descargarPDFreturn',
    ]),
    ...mapActions('conceptCategories', [
      'addCategoriesSelect',
      'addCategoriesSelectAll',
      'removeCategoriesSelect',
      'clearCategoriesSelect',
    ]),
    ...mapActions('userProfile', ['loadData']),
    ...mapActions('modal', ['openModal']),
    ...mapActions('invoice', ['saveCommentInTaxReturn']),
    fetchInvoice() {
      this.fetchViewInvoice(this.invoiceId).then((response) => {
        this.invoice = response.data.invoice
        this.$forceUpdate()
      })
    },
    fetchPaidDocuments() {
      this.fetchInvoicePaidDocuments(this.invoiceId).then((response) => {
        this.paidDocuments = response.data
      })
    },

    deletePaidDocument(document) {
      this.fetchDeletePaidDocument({
        paid_document_id: document.id,
      })
        .then((response) => {
          this.fetchPaidDocuments()
          window.toastr['success'](
            this.$t('invoices.messages.paid_document_deleted')
          )
        })
        .catch((err) => {
          window.toastr['error'](
            this.$t('invoices.errors.delete_paid_document')
          )
        })
    },

    documentInTaxReturn(document) {
      const data = {}
      if (document.sys_type == 'transaction') {
        data['type'] = 'conciliation'
        data['model_id'] = this.invoice.id
        data['transaction_id'] = document.id
      } else {
        data['type'] = 'paid_document'
        data['model_id'] = document.id
      }
      data['in_tax_return'] = document.in_tax_return

      this.fetchDocumentSaveInTaxReturn(data)
        .then((response) => {
          if (response.data.success) {
            this.fetchInvoice()
            this.fetchPaidDocuments()
            if (document.in_tax_return) {
              window.toastr['success'](
                this.$t('invoices.messages.document_tax_return_added')
              )
            } else {
              window.toastr['success'](
                this.$t('invoices.messages.document_tax_return_removed')
              )
            }
          }
        })
        .catch((err) => {
          window.toastr['error'](
            this.$t('invoice.errors.cant_change_all_tax_return')
          )
        })
    },

    inTaxReturn(item) {
      const data = {
        invoice_id: this.invoice.id,
        invoice_item_id: item.id,
        in_tax_return: item.in_tax_return ? 1 : 0,
      }
      this.saveInTaxReturn(data).then((response) => {
        if (response.data.success) {
          this.fetchInvoice()
          this.fetchPaidDocuments()
          if (item.in_tax_return) {
            window.toastr['success'](
              this.$t('invoices.messages.item_tax_return_added')
            )
          } else {
            window.toastr['success'](
              this.$t('invoices.messages.item_tax_return_removed')
            )
          }
        }
      })
    },
    inTaxReturnAll(check) {
      const ids = []
      this.concepts.forEach((item) => {
        ids.push(item.id)
      })
      const data = {
        invoice_id: this.invoice.id,
        invoice_items_ids: ids,
        in_tax_return: check,
      }
      this.saveInTaxReturnAll(data).then((response) => {
        if (response.data.success) {
          this.fetchInvoice()
          this.fetchPaidDocuments()
          if (item.in_tax_return) {
            window.toastr['success'](
              this.$t('invoices.messages.item_tax_return_added')
            )
          } else {
            window.toastr['success'](
              this.$t('invoices.messages.item_tax_return_removed')
            )
          }
        }
      })
    },
    isCategorySelected(item) {
      const itemString = JSON.stringify(item)
      return this.categoriesSelect.some(
        (selectItem) => JSON.stringify(selectItem) === itemString
      )
    },

    isInpuestoSelect() {
      return this.concepts.every(
        (item) => item.in_tax_return == true || item.in_tax_return == 1
      )
    },
    checkCategory(item) {
      const itemString = JSON.stringify(item)
      const includesItem = this.categoriesSelect.some(
        (selectItem) => JSON.stringify(selectItem) === itemString
      )

      if (includesItem) {
        this.removeCategoriesSelect(item)
        this.$forceUpdate()
      } else {
        this.addCategoriesSelect(item)
      }
    },

    checkInpuestoAll() {
      if (this.isInpuestoSelect()) {
        this.changeStatusItemsInTax(0)
      } else {
        this.changeStatusItemsInTax(1)
      }
    },

    changeStatusItemsInTax(inTax) {
      window
        .swal({
          title: 'Esta seguro de cambiar el estado para impuesto?',
          // text: this.$t('tax_returns.confirm_send_declaracion'),
          icon: '/assets/icon/file-earmark-spreadsheet.svg',
          buttons: true,
          dangerMode: true,
        })
        .then(async (value) => {
          if (value) {
            this.inTaxReturnAll(inTax)
          } else {
            // Si el usuario hizo clic en "cancelar", desmarcar la casilla
            this.$refs.inpuestoCheckbox.checked = false
          }
        })
    },

    checkCategoryAll() {
      if (this.categoriesSelect.length == this.concepts.length) {
        this.clearCategoriesSelect()
        this.$forceUpdate()
      } else {
        // Si el elemento no está en el array, agrégalo
        this.addCategoriesSelectAll(JSON.parse(JSON.stringify(this.concepts)))
        this.$forceUpdate()
      }
    },
    async setInitialData() {
      let response = await this.loadData()
      this.userName = response.data.name
    },

    openModelComment(id, comment) {
      if (comment == null) {
        this.comments_in_tax_return = []
      } else {
        if (typeof JSON.parse(comment)[0].user !== 'undefined') {
          this.comments_in_tax_return = JSON.parse(comment)
        } else {
          this.comments_in_tax_return = []
        }
      }
      this.id_comment = id
      this.modelComment = true
    },

    commentInTaxReturn() {
      var today = new Date()
      let fechaHoy =
        String(today.getDate()).padStart(2, '0') +
        '-' +
        String(today.getMonth() + 1).padStart(2, '0') +
        '-' +
        today.getFullYear()

      let comments = this.comments_in_tax_return
      comments.push({
        user: this.userName,
        fecha: fechaHoy,
        comentario: this.comment,
      })
      let data = {
        id: this.id_comment,
        comment: comments,
      }
      this.comment = ''
      this.saveCommentInTaxReturn(data)
        .then((res) => {
          window.toastr['success']('Comentario guardado con éxito.')
          this.fetchInvoice()
        })
        .catch((err) => {
          console.log(err)
        })

      this.modelComment = false
    },

    showApplyPaymentModal() {
      this.setDataModalAddPaidDocument({
        invoiceId: this.invoice.id,
        amount: (this.invoice.total - this.invoice.related_amount) / 100,
      })
      this.openModal({
        title: this.$t('invoices.apply_payment'),
        componentName: 'AddPaidDocumentModal',
      })
    },

    descargarPDF(id) {
      window.open(`/api/invoice-item/download-pdf/${id}`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
}
.amount-cell {
  min-width: 85px;
}

.amount-cell-md {
  min-width: 100px;
}

.complement-title {
  font-weight: 400;
  text-align: center;
}

.invoice-info {
  tr {
    th,
    td {
      font-size: 13px;
      vertical-align: baseline;
      width: 33.3%;

      strong {
        font-weight: bold !important;
        text-transform: uppercase;
      }
    }
  }
}

.link {
  text-decoration: underline;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
</style>
