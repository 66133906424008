var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-content treasuries-accounts" },
    [
      _c("div", { staticClass: "page-header mt-5" }, [
        _c("h3", { staticClass: "page-title" }, [
          _vm._v(
            "\n      " +
              _vm._s(_vm.$t("treasury_accounts.page_title")) +
              "\n      | " +
              _vm._s(_vm.filters.month["name"]) +
              ",\n      " +
              _vm._s(_vm.filters.year) +
              "\n    "
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "page-actions row" },
          [
            _c(
              "router-link",
              {
                staticClass: "mr-2",
                attrs: {
                  to: {
                    name: "account.index",
                  },
                },
              },
              [
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-center",
                        value: _vm.Cuentas,
                        expression: "Cuentas",
                        modifiers: { "top-center": true },
                      },
                    ],
                    attrs: { outline: true, color: "theme", size: "large" },
                  },
                  [_vm._v("\n          Cuentas\n        ")]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              {
                staticClass: "mr-2",
                attrs: {
                  to: {
                    name: "general-public-invocie-treasury.index",
                  },
                },
              },
              [
                _c(
                  "base-button",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.top-center",
                        value: _vm.$t("payments.general_public_invoices.title"),
                        expression:
                          "$t('payments.general_public_invoices.title')",
                        modifiers: { "top-center": true },
                      },
                    ],
                    attrs: {
                      outline: true,
                      color: "theme",
                      icon: "file-alt",
                      size: "large",
                    },
                  },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("payments.general_public_invoices.abbr")
                        ) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "router-link",
              { attrs: { to: { name: "treasury_accounts.create" } } },
              [
                _c(
                  "base-button",
                  { attrs: { color: "theme", icon: "plus", size: "large" } },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("treasury_accounts.add_transaction")) +
                        "\n        "
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm._l(_vm.treasuries, function (cuenta, index) {
        return _c(
          "div",
          { key: cuenta },
          [
            _c(
              "b-card-header",
              { attrs: { "header-tag": "header", role: "tab" } },
              [
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "b-toggle",
                        rawName: "v-b-toggle",
                        value: "collapse-cuenta-" + index,
                        expression: "'collapse-cuenta-' + index",
                      },
                    ],
                    staticClass:
                      "d-flex justify-content-between align-items-center",
                    attrs: { variant: "primary" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "pl-2 font-weight-bold text-uppercase" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(cuenta.name) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("font-awesome-icon", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: {
                                content: _vm.$t(
                                  "treasury_accounts.upload_transactions"
                                ),
                              },
                              expression:
                                "{\n              content: $t('treasury_accounts.upload_transactions'),\n            }",
                            },
                          ],
                          staticClass: "icon mr-2",
                          attrs: { icon: "plus" },
                          on: {
                            click: function ($event) {
                              return _vm.importarTransaccion(cuenta.id)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-collapse",
              {
                staticClass: "my-2",
                attrs: { id: "collapse-cuenta-" + index, visible: false },
              },
              [
                _c(
                  "table-component",
                  {
                    ref: "table",
                    refInFor: true,
                    attrs: {
                      data: cuenta.treasury_accounts,
                      "filter-no-results": _vm.$t("general.filterNoResults"),
                      "is-transactions-list": true,
                      "show-filter": false,
                      "table-class": "table",
                    },
                  },
                  [
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("treasury_accounts.treasury_date"),
                        "sort-as": "treasury_date",
                        show: "treasury_date",
                      },
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: "Descripción", show: "name" },
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: "N/A",
                        sortable: false,
                        filterable: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "text-center" }, [
                                  _c("input", {
                                    staticClass: "form-check-input",
                                    attrs: { type: "checkbox" },
                                    domProps: { checked: row.not_applicable },
                                  }),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: "Depositos", show: "payments_amount" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("treasury_accounts.payments"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "flex-row flex-center" },
                                  [
                                    _c("div", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$utils.formatMoney(
                                            row.payments_amount,
                                            _vm.defaultCurrency
                                          )
                                        ),
                                      },
                                    }),
                                    _vm._v(" "),
                                    row.payments_amount > 0 &&
                                    row.not_applicable == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "px-3 space-left" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "treasury_accounts.relate_invoices",
                                                    params: {
                                                      type: "payments",
                                                      id: row.id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: "plus-circle",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: "Retiros", show: "expenses_amount" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.$t("treasury_accounts.expenses"))
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-center d-flex align-items-center px-3",
                                  },
                                  [
                                    _c("div", { staticClass: "mr-2" }, [
                                      _c("span", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$utils.formatMoney(
                                              row.expenses_amount,
                                              _vm.defaultCurrency
                                            )
                                          ),
                                        },
                                      }),
                                    ]),
                                    _vm._v(" "),
                                    row.expenses_amount > 0 &&
                                    row.not_applicable == 0
                                      ? _c(
                                          "div",
                                          { staticClass: "px-3 space-left" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "treasury_accounts.relate_invoices",
                                                    params: {
                                                      type: "expenses",
                                                      id: row.id,
                                                    },
                                                  },
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    icon: "plus-circle",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: { label: _vm.Saldo, show: "Saldo" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [_vm._v("Saldo")]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass: "text-center",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$utils.formatMoney(
                                        row.total,
                                        _vm.defaultCurrency
                                      )
                                    ),
                                  },
                                }),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: _vm.$t("payments.general_public_invoices.abbr"),
                        sortable: false,
                        filterable: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.$t(
                                          "payments.general_public_invoices.abbr"
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                                _vm._v(" "),
                                row.payments_amount > 0 &&
                                row.not_applicable == 0
                                  ? _c(
                                      "div",
                                      { staticClass: "text-center" },
                                      [
                                        !row.general_public_invoiced_at
                                          ? [
                                              _c("input", {
                                                staticClass: "form-check-input",
                                                attrs: {
                                                  type: "checkbox",
                                                  value: "1",
                                                },
                                                domProps: {
                                                  checked:
                                                    row.general_public_invoice,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    return _vm.toggleRowGeneralPublicInvoice(
                                                      row
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          : _vm._e(),
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                    _vm._v(" "),
                    _c("table-column", {
                      attrs: {
                        label: "Importe sin relacionar",
                        sortable: false,
                        filterable: false,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "default",
                            fn: function (row) {
                              return [
                                _c("div", { staticClass: "text-center" }, [
                                  _c("div", {
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$utils.formatMoney(
                                          row.remanente,
                                          _vm.defaultCurrency
                                        )
                                      ),
                                    },
                                  }),
                                ]),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }