<template>
  <div class="main-content">
    <div class="page-header mt-4">
      <h3 class="page-title">
        Carga manual de CFDI
      </h3>
    </div>

    <div class="card mt-3 pt-4 pb-4">
      <div class="row">
        <div class="col-12 col-md-6 offset-md-3">
          <form @submit.prevent="submit">
            <div class="form-group">
              <input type="file" class="form-control" accept=".xml,.zip" @change="readFile" />
            </div>

            <div class="form-group text-center">
              <button class="btn btn-primary" type="submit">
                {{ $t('general.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  data: () => ({
    file: null,
  }),
  computed: {
    ...mapGetters('CurrentCustomer', ['currentCustomer']),
  },
  methods: {
    ...mapActions('invoiceManual', ['sendXml']),
    readFile(event) {
      const input = event.target
      this.file = input.files[0]
    },
    submit() {
      const formData = new FormData()
      if (this.file.type === 'text/xml') {
        formData.append('xmlFile', this.file)
      } else {
        formData.append('zipFile', this.file)

      }
      formData.append('customer_id', this.currentCustomer.id)
      this.sendXml(formData)
        .then((response) => {
          console.log(response.status);
          if (response.status == 200) {
            this.$router.push('/general/payments')

          }
        })
        .catch((err) => {

        })
    },
  },
}
</script>
