export const PaidDocumentOptions = [
  {
    name: 'direct_collection',
    description: 'Cobro directo',
  },
  {
    name: 'credit_note',
    description: 'Nota de crédito',
  },
  {
    name: 'cash',
    description: 'Efectivo',
  },
  {
    name: 'statement_account',
    description: 'Estado de cuenta',
  },
  {
    name: 'credit_card',
    description: 'Tarjeta de Credito',
  },
  {
    name: 'debit_card',
    description: 'Tarjeta de debito',
  },
  {
    name: 'transfer',
    description: 'Transferencia',
  },
  {
    name: 'others',
    description: 'Otros',
  },
]
